@import "../../assets/scss/variables.scss";
.BookNow {
  background: $text-gray-900;
  padding: 0 0 50px 0;
  .logos{
    height: 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    p{
      margin: 0;
    }
  }
  .rosso{
    background-image: url(../../assets/images/FloreRosso1.png);
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .company{
    height: 70px;
    width: 130px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .banner {
    height: 760px;
    background-color: url(../../assets/images/donate.png) no-repeat;
    background-size: 100%;
    margin: 100px 0 50px 0;
  }
  h2 {
    margin: 0;
    padding: 30px 0 30px 0;
    display: flex;
    flex-direction: row;
    font-size: $test-medium-xsmall;
    font-weight: $f-w-800;
    color: $text-black-500;
    align-items: flex-end;
    span {
      // text-transform: capitalize;
      color: $text-black-500;
      font-size: $test-small-light;
    }
    .btn-outline-primary {
      margin-right: 20px;
      padding: 8px 50px;
      font-size: $test-sm;
      color: $text-red-700;
      font-weight: $f-w-700;
      border-radius: $radius3;
      border: 2px solid $text-red-700;
      background-color: transparent !important;
      &:hover,
      &:active {
        color: $text-red-700;
        border: 2px solid $text-red-700 !important;
        background-color: transparent !important;
      }
    }
  }
  .donnerDetails {
    padding: 30px;
    background: $text-white;
    border-radius: $radius7;
    .activeSlot {
      button {
        box-shadow: none;
        color: $text-red-50 !important;
        background-color: $text-red-900 !important;
        &:hover,
        &:active,
        &:focus {
          color: $text-red-50 !important;
          background-color: $text-red-900 !important;
        }
      }
    }
    .col-md-4 {
      .btn.btn-primary {
        border: 0;
        width: 100%;
        padding: 10px 0;
        margin: 20px 0 0 0;
        font-size: $test-sm;
        font-weight: $f-w-700;
        color: $text-gray-1007;
        border-radius: $radius3;
        box-shadow: none;
        background-color: $text-gray-1003;
        &:hover,
        &:active {
          border: 0 !important;
          border-radius: $radius3;
          background-color: $text-gray-1003;
        }
      }
    }
    h3 {
      text-transform: capitalize;
      margin: 0;
      padding: 0;
      font-size: $text-lg;
      font-weight: $f-w-700;
      color: $text-blackgray-100;
    }
    .info {
      display: flex;
      flex-direction: row;
      padding: 25px 0 0 0;
      svg {
        margin-right: 10px;
      }
      a {
        color: $text-blackgray-100;
        font-weight: $f-w-700;
        text-decoration: underline;
      }
    }
    .profileInfo {
      h4 {
        margin: 0;
        margin-left: 10px;
        font-weight: $f-w-700;
        color: $text-black-800;
        font-size: $test-small-light;
      }
      h6 {
        margin: 0;
        margin-left: 10px;
        color: $text-gray-600;
        span {
          color: $text-gray-600;
          font-size: $text-small;
        }
      }
    }
    .divider {
      margin: 30px 0;
      border-top: 1px solid $text-gray-1002;
    }
    h5 {
      margin: 0;
      padding: 0 0 30px 0;
      font-weight: $f-w-700;
      color: $text-blackgray-100;
      font-size: $test-small-light;
    }
    .btnActive {
      padding: 0;
      width: 90px;
      height: 32px;
      box-shadow: none;
      color: $text-white !important;
      background-color: $text-red-900 !important;
      .tick {
        display: inline-block !important;
        width: 16px;
      }
    }

    .booked {
      padding: 0;
      width: 90px;
      height: 32px;
      border: 0;
      border-radius: 99px;
      color: $text-gray-1000;
      background-color: $text-gray-1003;
      &:hover,
      &:active {
        background-color: $text-gray-1003;
      }
      svg.booked {
        display: inline;
        width: 16px;
      }
    }
    .normalBtn {
      padding: 0;
      width: 90px;
      height: 32px;
    }
    button {
      border: 0 !important;
      padding: 8px 50px;
      color: $text-red-700;
      font-size: $test-sm;
      border-radius: $radius3;
      margin-right: 25px;
      margin-bottom: 20px;
      background-color: $text-red-800;
      // border: 2px solid $text-red-700;
      svg {
        display: none;
      }
      &:hover,
      &:active {
        border: 0 !important;
        color: $text-red-700;
        font-size: $test-sm;
        background-color: $text-red-800;
        // border: 2px solid $text-red-700 !important;
      }
    }
    .booked {
      cursor: no-drop;
      opacity: 0.4;
      border: 0;
      border-radius: 99px;
      color: $text-gray-1000;
      background: $text-gray-1003;
    }
    p {
      color: $text-blackgray-100;
    }

    .card {
      border-radius: 0;
      border-color: $text-gray-500;
      border-style: solid;
      border-width: 0 0 0 1px;
      padding: 0 0 0 50px;
      h5 {
        color: $text-black-800;
        font-weight: $f-w-700;
        font-size: $test-small-big;
        font-family: "Nunito", sans-serif;
      }
      h6 {
        color: $text-gray-600;
        font-weight: $f-w-400;
        font-size: $test-small;
        font-family: "Nunito", sans-serif;
      }
      p {
        padding: 0;
        color: $text-gray-600;
        font-weight: $f-w-400;
        font-size: $test-small-light;
        font-family: "Nunito", sans-serif;
        strong {
          color: $text-black-800;
          font-weight: $f-w-700;
          font-size: $test-small-light;
          font-family: "Nunito", sans-serif;
        }
      }
    }
    // p{
    //     padding: 100px;
    //     color: $text-gray-600;
    //     font-weight: $f-w-400;
    //     font-size: $test-small-big;
    //     font-family: 'Raleway', sans-serif;
    // }
  }
  .slots {
    padding: 0 0 100px 0;
    h2 {
      font-size: $text-3xl;
      font-weight: $f-w-800;
      color: $text-black-800;
      font-family: "Raleway", sans-serif;
    }
    p {
      margin: 0;
      padding: 15px 0 20px 0;
      color: $text-gray-400;
      font-size: $test-small;
      font-weight: bold;
    }
    button {
      font-weight: bold;
      padding: 10px 20px;
      margin: 0 10px 10px 0;
    }
    .booked {
      cursor: no-drop;
      opacity: 0.4;
    }
  }
  .slotFull {
    position: relative;
    h4 {
      display: flex;
      position: absolute;
      top: -15px;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      background: #bb0f3170;
      height: 35px;
      color: #bb0f31;
      z-index: 999;
      border-radius: 5px;
      padding: 20px 5px;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: $test-small-light;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      span {
        font-size: $test-sm;
        position: relative;
        top: 5px;
      }
    }
    .generalLogo {
      left: 68%;
      width: 40px;
    }
    .closeIcons {
      left: 58.5%;
    }
  }
}
