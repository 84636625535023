@import '../../assets/scss/variables.scss';

._modal {

    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 999;
    width: 100%;
    ._modal__title {
        font-size: 24px;
        font-family: "Raleway", sans-serif;
        font-weight: 700;
        padding: 20px;
        padding-bottom: 0;
        color: $text-blackgray-100;
        text-align: center;
    }

    ._modal__body {
        padding: 20px;
        padding-top: 20px;
        font-size: 16px;
        font-family: "Raleway", sans-serif;
        text-align: center;
    }

    ._modal__overlay {
        position: absolute;
        z-index: 10;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        animation: fadein 0.5s;
        background-color: rgba(0, 0, 0, 0.51);
    }

    ._modal__main {
        z-index: 11;
        max-width: 500px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
        height: fit-content;
        animation: scalemain 0.4s;
        border-radius: 22px;
        box-shadow: 0 4px 6px rgb(32 33 36 / 18%);
        max-height: 700px;
        overflow: auto;
        background-color: $text-gray-900;
        padding: 28px 24px 28px 24px;
    }
    ._modal__main_closing{
        animation: scalemainreverse 0.3s;
    }
    ._modal__overlay_closing{
        animation: fadeout 0.4s;
    }
    ._modal__actions {
        display: flex;
        justify-content: center;
        gap: 10px;
        padding-top: 10px;
        border-radius: 6px;
        padding-bottom: 10px;
        padding-right: 10px;
        button{
            padding: 6px 16px;
            min-width: 173px;
        }
    }

    ._modal__actions_button {
        margin-left: 10px;
        font-size: 0.775rem
    }

    .redButtonShallow{
        border-color: #BA0F31 !important;
        color: #BA0F31 !important;
    }

    .redButton{
        background-color: #BA0F31 !important;
        color: #fff !important;
    }


}


@-webkit-keyframes fadein {
    from {

        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {

        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes scalemain {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes scalemain {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes scalemainreverse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@-webkit-keyframes scalemainreverse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}