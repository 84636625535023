.MenuBar {
    display: flex;
    align-items: center;
    height: 100%;
    .navbar-nav{
        a{
            &:nth-last-child(1),&:nth-last-child(2){
                display: none;
            }
        }
    }
}