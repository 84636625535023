@import '../../assets/scss/variables.scss';
.FindPlace {
    padding: 0;
    .showMap{
        padding: 50px 0;
        background: $text-gray-900;
        h2{
            font-weight: $f-w-700;
            color: $text-black-500;
            font-size: $test-medium-xsmall;
        }
        h5{            
            font-weight: $f-w-700;
            color: $text-gray-1004;
            font-size: $test-small-light;
        }
        form{
            .formGroup{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                input{
                    width: 85%;                    
                    border-radius: $radius3;
                    padding: 0.475rem 0.75rem;
                }
                .btn-outline-primary{
                    padding: 8px 20px;
                    font-size: $test-sm;
                    color: $text-red-700;                    
                    font-weight: $f-w-700;
                    border-radius: $radius3;
                    border: 2px solid $text-red-700;
                    &:hover,&:active{
                        font-size: $test-sm;
                        color: $text-red-700;                    
                        font-weight: $f-w-700;
                        border: 2px solid $text-red-700 !important;
                    }
                }
            }        
        }
        .mapList{
            height: 64vh;
            overflow: auto;
            .MapCard{
                &:last-child{
                    //padding: 50px 0 0 0;
                }
            }
        }
    }
    .mapImg{
        width: 100%; height: 100%;
    } 
    .mapList{
        padding-bottom: 50px;
    }
}

@media (max-width: 768px) {
    .showMap{
        padding: 0 !important;
    }
    .mapList{
        padding-bottom: 0 !important;
    }
    .map{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}