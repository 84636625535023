.HomePage {
}

@media (min-width: 320px) and (max-width: 960px) {
  .HomePage {
    margin-top: -400px;
    padding-top: 400px;
  }
}
@media (min-width: 961px) {
  .HomePage {
    margin-top: -100px;
    padding-top: 100px;
  }
}
