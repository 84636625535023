@import '../../../assets/scss/variables.scss';
.CardEight {
    padding:20px 0 10px 0;
    display: flex;
    height: 100%;
    .donation_done{
        color: #33A9A9 !important;
    }
    .donation_cancelled{
        color: #9696A0 !important;
    }
    .disabled{
        background-color: #EDEDF0 !important;
        color: #9696A0 !important;
    }
    .card{
        padding: 30px;
        border:0 !important;
        box-shadow: $shadow3;
        border-radius: $radius1 !important;
        width: 100%;
        // border:0 !important;
    // box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.1);
    .card-body{
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
               
            .card-title{                
                margin: 0;                 
                color: $text-red-700;
                display: flex;
                padding: 0 0 30px 0;
                flex-direction: row;
                align-items: center;                
                font-weight: $f-w-700;
                svg{
                    width: 30px; height: 30px; margin-left: 10px;
                }
            }
            .card-center{
            .card-subtitle{           
                margin: 0;
                padding: 0 0 20px 0;     
                font-size: $text-lg;    
                font-weight: $f-w-700;
                padding-right: 20px;
                text-overflow: ellipsis;
                color: $text-blackgray-100;
                text-wrap: wrap;
                display: flex;
            }
            .card-content{
                color: $text-blackgray-100; 
                path{
                    stroke: #292A31;
                }         
                .details{
                    height: 48px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    svg{
                        margin-right: 10px;
                    }
                    &:last-child{
                        margin: 10px 0 30px 0;
                        svg{
                            margin-right: 15px;
                        }   
                    }
                }                                
            }
            }
            .card-footer{
                margin: 0;
                padding: 0;
                border: 0;
                display: flex;
                flex-direction: column;
                background: transparent;
                .btn-primary{
                    border: 0;                                    
                    color: $text-white;
                    font-size: $test-sm;
                    letter-spacing: 1px;
                    font-weight: $f-w-700;
                    border-radius: $radius3;
                    padding: 0.600rem 0.75rem;
                    background-color: $primary;
                }
                .btn-outline-primary{                    
                    padding: 8px 0;
                    color: $primary;
                    margin: 20px 0 0 0;
                    font-size: $test-sm;
                    font-weight: $f-w-700;
                    border: 2px solid $text-red-200;
                    &:hover,&:active{
                        color: $primary;
                        background: transparent;
                        border: 2px solid $text-red-200;
                    }
                }   
            }
    }   
    }
}