@import '../../../assets/scss/variables.scss';
.notificationNumberList{
    .list-group{
            .list-group-item{
                border: 0;   
                font-size: $text-lg;
                font-weight: $f-w-400;
                padding: .5rem 1rem 1.5rem 0;                
                font-family: 'Inter', sans-serif;
            }
            .active{
                color:$text-black-500;
                font-weight: $f-w-700;
                background: $text-blue-300;
            }
    }
}