@import "../../assets/scss/variables.scss";
.AdminMenuBar {
  width: 100%;
  .profile {
    // padding: 0 0 25px 0;
    button {
      padding: 0;
      border: 0;
      background: transparent;
      &::after {
        content: none;
      }
    }
    .logo {
      padding: 25px 0;
      text-align: center;
      border-bottom: 1px solid $border4;
      img {
        height: 50px;
      }
    }
    .userDetails {
      padding: 15px 20px;
    }
    h6 {
      display: inline;
      margin: 0;
      padding: 0 0 2px 5px;
      font-size: $test-sm;
      font-weight: $f-w-700;
      color: $text-blackgray-200;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-family: "Raleway", sans-serif;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    p {
      color: $text-gray-1008;
      margin: 0;
      padding: 0 0 0 5px;
      font-size: $text-small;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-family: "Raleway", sans-serif;
    }
  }
  .navbar {
    display: none;
  }
  nav.menu-md {
    ul {
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: flex-start;
        list-style-type: none;
        margin: 0 0 20px 0;
        a {
          margin: 0;
          width: 100%;
          color: $text-blackgray-300;
          padding: 10px 20px;
          font-size: $test-sm;
          font-weight: $f-w-700;
          font-family: "Raleway", sans-serif;
          svg {
            margin-right: 15px;
          }
        }
        .active {
          color: $active;
          // background: $text-black-1001;
          svg {
            // fill: $active;
            path {
              stroke: $active;
              // fill: $active;
            }
          }
        }
        &:nth-child(4) {
          .active {
            svg {
              path {
                fill: $active;
                // stroke: transparent;
              }
            }
          }
        }
        &:nth-child(5) {
          .active {
            svg {
              path {
                fill: $active;
                stroke: transparent;
              }
            }
          }
        }
      }
    }
  }
  .mobile-nav {
    .container-fluid {
      justify-content: flex-start;
    }
    .dropdown-item {
      padding: 10px;
    }
    .logo {
      display: block;
      margin: 0 auto;
      img {
        height: 40px;
      }
    }
  }
  .dropdown-menu {
    right: 5px;
    padding: 10px;
    p {
      font-size: 0.9rem !important;
    }
  }
}

@media (max-width: 650px) {
  .AdminMenuBar {
    .dropdown-menu {
      top: 50px !important;
    }
  }
}
