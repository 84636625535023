
@import '../../assets/scss/variables.scss';

.Header {
    box-shadow: 0px 2px 2px rgba(0,0,0,.1);
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 0;
    background: $text-white;
    z-index: 999;
    .logos{
        display: flex;
        align-items: center;
        gap: 10px;
        p{
            margin: 0;
        }
    }
    .innerContainer{
        .logo{
            width:50px;
        }
        .logo-brand{
            width: 80px;
        }
        .closeIcons{
            top: 50%;
            left:11%;
            transform: translateY(-50%);
            // transform: translate(-50%, -50%);
            cursor: pointer;
            position: absolute;
        }
        .generalLogo{
            width: 150px;
            position: absolute;
            top: 50%;
            left: 50%;            
            transform: translate(-50%, -50%);
        }
        .mlogo{
            display: none;
        }
        .bg-body-tertiarys{
            .navbar-nav{
                display: flex;
                width: 100%;
                // justify-content: space-between;
                    a{
                        margin: 0 50px 0 0;
                        border-bottom: 2px solid transparent;
                        position: relative;
                        font-size: $test-small;                       
                        &:nth-child(4){
                            margin: 0;
                        }
                        &:hover{
                            &:after{
                                content: '';
                                width:33px;
                                position: absolute;
                                left: 9px; bottom: 0;
                                border-bottom: 2px solid $primary;
                            }                
                        }
                    }
                }
        }
        // var(--bs-dropdown-link-hover-bg)
        .profileSettings{
            display: flex;
            align-items: center;
            justify-content: space-between;  
            // position: relative;          
            .nav{
                    li{                
                        display: flex;
                        flex-direction: row;
                        a{
                            position: relative;
                            color: $text-current;
                            font-weight: $f-w-400;
                            font-size: $test-small;                            
                            border-bottom: 2px solid transparent;
                            &:hover{
                                &:after{
                                    content: '';
                                    width:33px;
                                    position: absolute;
                                    left: 17px; bottom: 0;
                                    border-bottom: 2px solid $primary;
                                }                
                            }
                        }
                }
            }
            .profile{
                width: auto;
                max-width: 260px;               
                button{
                    border: 0;
                    background: transparent;                    
                    text-align: right;
                    text-transform: capitalize;
                    color: $text-current;
                    font-size: $test-small;
                    width: 150px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: inline-block;
                    &:after{
                        display: none;
                    }
                }
            
                .dropdown-menu{
                    margin: 26px 0 0 0;
                    padding: 5px;
                    color: $text-blackgray-100;
                    background: $text-gray-1003;
                    a{
                        display: flex;
                        flex-direction: row;
                        // justify-content: space-between  ;
                        color: $text-blackgray-100;
                        font-weight: $f-w-700;
                        padding: 0.400rem 0.7rem;
                        svg{
                            margin-right: 10px;
                        }
                        &:hover,&:active,&:focus{
                            color:$text-blackgray-100;
                            background: $border4;
                            border-radius: $radius9;
                        }
                    }
                }
                //     a{

                //     &:active{
                            
                //             background-color: var(--bs-dropdown-link-hover-bg);
                //     }
                // }

        
            }
        }
    }    
}
.GeneraliHeader{
    box-shadow: 0px 2px 2px rgba(0,0,0,.1);
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 0;
    background: $text-white;
    z-index: 999;  
    .innerContainer{
        img{
            width: 150px;
        }
        .generalLogo{
            width: 150px;
            position: absolute;
            top: 50%;
            left: 50%;            
            transform: translate(-50%, -50%);
        }
    }    
}

@media (max-width: 768px) {
    .profile{
        margin-top: 26px; 
    }
}

@media (min-width: 1024px){
    .innerContainer{
       max-width: 1140px !important
    }
}

@media (min-width: 1400px){
    .innerContainer{
        max-width: 1320px !important
    }
   
}