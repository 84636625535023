@import "../../../assets/scss/variables.scss";
.notificationList {
  button {
    color: $primary;
    font-size: $test-sm;
    letter-spacing: 1px;
    font-weight: $f-w-700;
    border-radius: $radius3;
    padding: 0.5rem 0.75rem;
    background: transparent;
    border: 2px solid $text-red-200;
    &:hover,
    &:focus {
      background: $text-white;
      color: $primary;
    }
  }
}
