@import "../../assets/scss/variables.scss";
.Conventions {
  .search {
    // width: 360px;
    background: url(../../assets/images/search.png) no-repeat 12px 9px $text-white;
    border: 0;
    outline: 0;
    box-shadow: $shadow9;
    padding: 8px 10px 8px 45px;
  }
  h2 {
    margin: 0;
    padding: 0 0 30px 0;
    font-weight: $f-w-700;
    font-size: $test-medium-xsmall;
    font-family: "Raleway", sans-serif;
  }
  .btn-outline-primary {
    margin-right: 20px;
  }
  p {
    padding: 0;
    margin: 0 0 8px 0;
    font-size: $test-sm;
    color: $text-gray-1000;
    font-family: "Raleway", sans-serif;
  }
  .table {
    margin: 20px 0 0 0;
    span {
      color: $text-indigo-70;
      cursor: pointer;
    }
    th {
      border-bottom: 2px solid $text-gray-1001;
      color: $text-gray-1001;
    }
    td {
      color: $text-black-1003;
      background-color: $text-white;
    }
  }
}

@media (max-width: 768px) {
  .Conventions {
    .search {
      margin-bottom: 10px;
    }
    .buttons {
      .UseButton {
        margin: 10px 0;
      }
    }
  }
}
@media (max-width: 650px) {
  .Conventions {
    .search {
      margin-bottom: 10px;
    }
    .buttons {
      .UseButton {
        margin: 10px 0;
      }
    }
  }
}
