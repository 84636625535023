@import '../../assets/scss/variables.scss';
.BookSlot {
    background: $text-gray-900;
    padding: 50px 0 100px 0;
    .partner{
        height: 40px;
        margin-left: 10px;
    }
 
    .bookSlotes{    
        h2{
            display: flex;
            flex-direction: row;
            color: $text-black-500;
            font-weight: $f-w-700;
            align-items: flex-end;
            font-size: $test-medium-xsmall;
            span{
                font-weight: $f-w-700;
                color: $text-black-500;
                font-size: $test-small-light;
            }
            button{
                padding: 8px 50px;
                color: $text-red-700;
                font-size: $test-sm;                
                border-radius: $radius3;
                margin-right: 25px;
                background-color: transparent;
                border: 2px solid $text-red-700;
                &:hover,&:active{
                    color: $text-red-700;
                    font-size: $test-sm;              
                    background-color: transparent !important;
                    border: 2px solid $text-red-700 !important; 
                }
            }
        }
        p{
            color: $text-black;
        }
        .slotDetails{
            background: $text-white;
            border-radius: $radius7;
            box-shadow: $shadow10;
        }
    }


    // h2{
    //     font-family: 'Inter', sans-serif;
    //     font-weight: $f-w-700;
    //     color: $text-black;
    // }
    // h5{

    // }
    // Button{
    //     padding: 18px 30px;
    //     font-family: 'Poppins', sans-serif;
    //     font-weight: $f-w-500;
    // }
}

@media (max-width: 767px) {
    h2{
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 20px;
    }
    .showmap{
        > .row{
            flex-direction: column;
        }
        .sendSlots {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            width: 100%;
            height: 400px;
            .UseButton{
                order: 1;
                width: 100%;
            }
        }
    }
  }
  