@import '../../assets/scss/variables.scss';
.AddConventions {
    h2{
        margin: 0;
        padding: 0 0 30px 0;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
        font-family: 'Raleway', sans-serif;
    }
    .text-muted{
        svg{
            margin-right: 10px;
        }     
    }
    .imageUpload{
        .logoPreview{right: 12px;}
    }
}