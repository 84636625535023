@import '../../assets/scss/variables.scss';

.AdminSettings {
    h2 {
        margin: 0;
        padding: 30px 0 30px 0;
        font-weight: 700;
        font-size: 2.125rem;
        font-family: "Raleway", sans-serif;
    }

    .settings-tabs {
        padding: 25px 0 0 0;

        .nav {
            border: 0;

            li {
                button {
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    font-weight: $f-w-700;
                    color: $text-black-500;
                    background: transparent;
                    background-color: transparent !important;
                    border-bottom: 2px solid transparent;
                    font-family: 'Raleway', sans-serif;

                    &:hover {
                        border: 0;
                    }
                }

                .nav-link.active {
                    color: $active;
                    border-bottom: 2px solid $active;
                    font-family: 'Raleway', sans-serif;
                }
            }
        }

        .table {

            // margin: 20px 0 0 0;
            span {
                color: $text-indigo-70;
                cursor: pointer;
            }

            th {
                border-bottom: 2px solid $text-gray-1001;
                color: $text-gray-1001;
            }

            td {
                color: $text-black-1003;
                background-color: $text-white;
            }

            .user_name,
            .user_id {
                color: $text-indigo-70;
            }
        }

        .logoPreview {
            width: 76px;
            height: 76px;
            border: 0;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 50%;                            
            img {
                width: 100%;
                border-radius: 50%;
            }
            span{
                top: auto;
                left: auto;
                right: 0;   
                bottom: 0;          
                display: flex;          
                width: 25px; height: 25px;          
                border-radius: 50%;
                cursor: pointer;
                position: absolute;
                align-items: center;
                justify-content: center;
                background: $text-gray-1000;  
                box-shadow: $shadow13;     
                svg{

                }
            }
        }

        .text-muted {
            top: -15px;
            color: $border4 !important;
            position: relative;

            svg {
                margin-right: 10px;
            }
        }

        .collabarations {
            position: relative;

            .adminBtnPrimary {
                display: flex;
                flex-direction: row;
                position: absolute;
                top: -51px;
                right: 0;

            }

            h2 {
                margin: 0;
                padding: 40px 0 20px 0;
                font-size: $text-lg;
                font-weight: $f-w-700;
                font-family: 'Raleway', sans-serif;
            }

        }
    }

    .profileSaveBtn {    
        position: absolute;
        bottom: 20px; right: 32px;
    }

    .modal__main {
        width: 430px;

        .modal__title {
            display: none;
        }

        .modal__body {
            padding: 0;

            h3 {
                margin: 0;
                padding: 0 0 20px 0;
                font-size: $text-lg;
                text-align: center;
                font-weight: $f-w-700;
                line-height: 2rem;
                color: $text-blackgray-100;
                font-family: 'Raleway', sans-serif;
            }

            p {
                text-align: center;
                margin: 0;
                padding: 0 0 25px 0;
            }
        }

        .modal__actions {
            margin: 0;
            padding: 0;

            button {
                letter-spacing: 1px;
                font-size: $test-sm;
                padding: 0.500rem .75rem;
            }

            .btn-outline-primary {
                letter-spacing: 1px;
                font-size: $test-sm;
                padding: 0.500rem .75rem;
            }
        }
    }

}