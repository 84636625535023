@import "../../assets/scss/variables.scss";
.TopBar {
  padding: 20px 0;
  a {
    font-weight: $f-w-700;
    color: $text-black-1003;
    font-size: $test-small-light;
    font-family: "Raleway", sans-serif;
  }
  svg {
    margin-right: 12px;
  }
  .dropdown-menu.show{
    padding: 5px;
    a{
      padding: 0.4rem 0.7rem;
      border-radius: $radius9;
      p{
        font-size: $test-base !important;
      }
    }
  }
  .settings {
    padding: 6px 0;
    cursor: pointer;
  }
  .dropdown {
    width: auto;
    button {
      padding: 0;
      border: 0;
      background: transparent;
      &::after {
        content: none;
      }
    }
  }
}
