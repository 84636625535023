@import "../../assets/scss/variables.scss";
.Reservations {
  padding: 40px 0 30px 0;
  background: $text-gray-900;
  .noSlot {
    text-align: center;
    margin: 0;
    padding: 50px 0 70px 0;
    a {
      font-weight: $f-w-700;
    }
  }
  .nav {
    border: 0;
    li {
      button {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        color: $primary;
        font-weight: $f-w-700;
        // color: $text-black-500;
        background: $text-red-300;
        border-bottom: 0;
      }
      .nav-link.active {
        color: $text-white;
        // border-bottom: 2px solid $active;
        background: $primary;
      }
    }
  }
  h2 {
    margin: 0;
    padding: 0 0 22px 0;
    font-weight: $f-w-700;
    color: $text-black-500;
    font-size: $test-medium-xsmall;
  }
  #Diary::before {
    content: " ";
    display: block;
    visibility: hidden;
    pointer-events: none;
  }
}
@media (min-width: 320px) and (max-width: 960px) {
  #Diary::before {
    margin-top: -400px;
    height: 400px;
  }
}
@media (min-width: 961px) {
  #Diary::before {
    margin-top: -160px;
    height: 160px;
  }
}
