@import "../../assets/scss/variables.scss";
.CompanyProfile {
  h2 {
    margin: 0;
    padding: 0 0 30px 0;
    font-weight: $f-w-700;
    text-transform: capitalize;
    font-size: $test-medium-xsmall;
    font-family: "Raleway", sans-serif;
  }
  .modal__main {
    width: 430px;
    .modal__body {
      padding: 0;
      h3 {
        margin: 0;
        padding: 0 0 20px 0;
        font-size: $text-lg;
        text-align: center;
        font-weight: $f-w-700;
        line-height: 2rem;
        color: $text-blackgray-100;
        font-family: "Raleway", sans-serif;
      }
      p {
        text-align: center;
        margin: 0;
        padding: 0 0 25px 0;
      }
    }
    .modal__actions {
      margin: 0;
      padding: 0;
      button {
        letter-spacing: 1px;
        font-size: $test-sm;
        padding: 0.6rem 0.75rem;
      }
      .btn-outline-primary {
        letter-spacing: 1px;
        font-size: $test-sm;
        padding: 0.5rem 0.75rem;
      }
    }
  }
  .logoInformazaioniPreview{
    width: 270px; height: 110px;
    position: absolute;
    top: 32px;
    left: 0;
    img{
      width: 100%; height: 100%;
      border: 0;
    }
    span{
      right: 0;   
      bottom: 0;          
      display: flex;          
      width: 40px; height: 40px;          
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      align-items: center;
      justify-content: center;
      background: $text-gray-1000;                
    }
  }
  .registerProfile {
    .CardBox {
      padding: 30px 0;
      .card {
        text-align: left;
        .card-body {
          width: 100%;
          h5 {
            text-align: left;
          }
        }
      }
    }
    .nav {
      border: 0;
      li {
        button {
          border-top: 0;
          border-left: 0;
          border-right: 0;
          font-weight: $f-w-700;
          color: $text-black-500;
          background: transparent;
          border-bottom: 2px solid transparent;
          font-family: "Raleway", sans-serif;
        }
        .nav-link.active {
          color: $active;
          border-bottom: 2px solid $active;
          font-family: "Raleway", sans-serif;
        }
      }
    }
    .text-muted {
      top: -15px;
      position: relative;
      opacity: .4;
      svg {
        margin-right: 10px;
      }
    }
    .form-check-label {
      font-size: $test-sm;
      font-weight: $f-w-700;
      color: $text-gray-1001;
      font-family: "Raleway", sans-serif;
    }
    .tab-pane {
      padding: 0 0 30px 0;
    }
    .textarea_count{      
      top: 5px;
      position: relative;
      text-align: right;
      font-size: $test-sm;
      margin: 0; padding: 0;      
      color: $text-gray-1001;
    }   
    .imageUpload {
      .logoPreview {
        right: 12px;
      }
      .logoWebinarPreview{
        width: 270px; height: 110px;
        position: absolute;
        top: 32px;
        left: 0;
        img{
          width: 100%; height: 100%;
          border: 0;
        }
        span{
          right: 0;   
          bottom: 0;          
          display: flex;          
          width: 40px; height: 40px;          
          border-radius: 50%;
          cursor: pointer;
          position: absolute;
          align-items: center;
          justify-content: center;
          background: $text-gray-1000;                
        }
      }
      .event-image-type{
        position: absolute;
        top: 145px;
        opacity: .4;
      }
     
      .logoEventPreview{        
        width: 270px; height: 110px;
        position: absolute;
        top: 32px;
        left: 12;
        img{
          width: 100%; height: 100%;
          border: 0;
        }       
        span{
          right: 0;   
          bottom: 0;          
          display: flex;          
          width: 40px; height: 40px;          
          border-radius: 50%;
          cursor: pointer;
          position: absolute;
          align-items: center;
          justify-content: center;
          background: $text-gray-1000;                
        }
      }
      .logo-types{
        top: 142px;
        left: 0;
        color: $border4;
        position: absolute;
      }
    }
    .copyLink {
      display: flex;
      justify-content: space-between;
      .mb-3 {
        width: 93%;
      }
      span {
        position: relative;
        top: 38px;
        cursor: pointer;
      }
    }
    .table {
      // margin: 20px 0 0 0;
      span {
        color: $text-indigo-70;
        cursor: pointer;
      }
      th {
        border-bottom: 2px solid $text-gray-1001;
        color: $text-gray-1001;
      }
      td {
        color: $text-black-1003;
        background-color: $text-white;
      }
      .user_name,
      .user_id {
        color: $text-indigo-70;
      }
    }
  }
  .event {
    position: relative;
    padding: 20px 0 0 0;

    .eventBtn {
      position: absolute;
      top: -51px;
      right: 0;
    }
    h4 {
      margin: 0;
      padding: 0 0 20px 0;
      font-size: $text-lg;
      font-weight: $f-w-700;
      color: $text-blackgray-100;
      font-family: "Raleway", sans-serif;
    }
  }
  .webinar {
    position: relative;
    h3 {
      padding: 20px 0 30px 0;
      margin: 0;
      font-size: $text-lg;
      font-weight: $f-w-700;
      color: $text-blackgray-100;
      font-family: "Raleway", sans-serif;
    }
    .adminBtnPrimary {
      position: absolute;
      top: -51px;
      right: 0;
    }
  }
}

@media (max-width: 650px) {
  .CompanyProfile {
    .event {
      .eventBtn {
        display: flex;
        position: relative;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
    .webinar {
      .adminBtnPrimary {
        display: flex;
        position: relative;
        justify-content: flex-end;
        margin-top: 20px;
        top: 0;
      }
    }
  }
}
