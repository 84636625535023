@import '../../../assets/scss/variables.scss';
.CardBox{    
    .card{
    border: 0;
    width: 100%;
    display: flex;    
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 20px 0;
    border-radius: $radius2;
    background-color: $text-gray-800;    
    h5{
        margin: 0; padding: 0 0 15px 0;
        text-align: center;
        color: $text-black-1003;
        font-size: $test-small-light;
        font-weight: $f-w-700;        
        font-family: 'Raleway', sans-serif;
    }
    h2{
        margin: 0; padding: 0 0 0 0;
        color: $text-white;
        font-weight: $f-w-700;
        font-size: $text-3xl-small;
        font-family: 'Raleway', sans-serif;
    }
}
}