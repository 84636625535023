@import "../../assets/scss/variables.scss";
.ActivateAccount {
  display: flex;
  height: 100vh;
  text-align: center;
  align-items: center;
  background: $adminBg;
  flex-direction: column;
  justify-content: space-between;
  background: $adminBg url(../../assets/images/heart2.png) no-repeat left bottom;
  background-size: 100%;
  .logintop {
    position: relative;
    top: 50px;
    h3 {
      margin: 0;
      position: relative;
      font-weight: $f-w-700;
      padding: 30px 0 10px 0;
      color: $text-blackgray-100;
      font-family: "Raleway", sans-serif;
      font-size: $test-medium-xsmall;
      svg {
        bottom: 0;
        right: -23px;
        position: absolute;
      }
    }
  }
  .innerLogin {
    width: 420px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    background: $text-white;
    border-radius: $radius7;
    box-shadow: $shadow8;

    // box-shadow: 0px 1px 18.100000381469727px 49px #3A3A440D;

    form {
      width: 100%;
      button {
        margin-top: 5px;
        width: 100%;
        background: $primary;
        font-size: $test-sm;
        letter-spacing: 1px;
        font-weight: $f-w-700;
        border-color: $active;
        border-radius: $radius3;
        padding: 0.57rem 0.75rem;
        background-color: $active;
        &:hover,
        &:focus {
          border-color: $active;
          background-color: $active;
        }
      }
      input {
        font-family: "Raleway", sans-serif;
        padding: 0.47rem 0.75rem;
        border-radius: $radius3;
        &:focus {
          border: 2px solid $text-blackgray-300;
        }
      }
      .form-check {
        padding: 0 0 20px 0;
        .form-check-input {
          margin-left: 0;
        }
        label {
          margin: 4px 0 0 10px;
          font-size: $test-sm;
        }
      }
      .form-check {
        display: flex;
        align-items: center;
        input[type="checkbox"] {
          height: 28px;
          width: 28px;
          border-radius: $radius2;
          border: 2px solid $text-blue-200;
          box-shadow: none;
          outline: 0;
        }
        input:checked {
          background-color: $text-blue-200;
          border-color: $text-blue-200;
        }
      }
      label {
        color: $text-gray-1001;
        font-size: $test-sm;
      }
      .eye {
        right: 11px;
        bottom: 10px;
        cursor: pointer;
        position: absolute;
      }
      .closeEmail {
        right: 11px;
        bottom: 12px;
        cursor: pointer;
        position: absolute;
      }
    }
    p {
      padding-bottom: 5px;
      margin: 0;
      font-family: "Raleway", sans-serif;
      font-size: $test-sm;
      color: $text-gray-300;
    }
    .customBtn {
      width: 100%;
      padding: 0.575rem 0.75rem;
      font-family: "Raleway", sans-serif;
      font-weight: $f-w-600;
      background: transparent;
      color: $text-black-500;
      border: 1px solid $text-gray-200;
    }
  }
}

@media (max-width: 767px) {
  .ActivateAccount {
    .logintop {
      h3 {
        font-size: 1.8rem;
      }
      svg {
        right: 0 !important;
      }
    }
    .innerLogin {
      width: 100% !important;
    }
  }
}
