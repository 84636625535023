@import "../../assets/scss/variables.scss";
.AdminSidebar {
  width: 233px;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  background: $text-gray-900;
  justify-content: flex-start;
  box-shadow: $shadow12;
  // clip-path: inset(0 -15px 0 0);
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  // h2{
  //     margin: 0;
  //     color: $text-white;
  //     font-weight: $f-w-900;
  //     font-size: $text-3xl-small;
  //     padding: 30px 0 50px 0;
  //     font-family: 'Inter', sans-serif;
  // }
}
