@import "../../assets/scss/variables.scss";

.AddUser {
    h2 {
        margin: 0;
        padding: 0 0 30px 0;
        font-weight: $f-w-700;
        text-transform: capitalize;
        font-size: $test-medium-xsmall;
        font-family: "Raleway", sans-serif;
    }

    .createReservation {
        position: relative;
    }

    .eventTypeWrap {
        display: flex;
        gap: 100px;
        padding: 20px 0 40px 0;
        margin-top: 20px;
    }

    .bookingState {
        position: absolute;
        top: 0;
    }

    .table {

        // margin: 20px 0 0 0;
        span {
            color: $text-indigo-70;
            cursor: pointer;
        }

        th {
            border-bottom: 2px solid $text-gray-1001;
            color: $text-gray-1001;
        }

        td {
            color: $text-black-1003;
            background-color: $text-white;
        }

        .user_name,
        .user_id {
            color: $text-indigo-70;
        }
    }

    .text-muted {
        top: 5px;
        color: $border4 !important;
        position: relative;

        svg {
            margin-right: 10px;
        }
    }

    .registerUser {
        .nav {
            border: 0;

            li {
                button {
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    font-weight: $f-w-700;
                    color: $text-black-500;
                    background: transparent;
                    border-radius: $radius14;
                    border-bottom: 4px solid transparent;
                    font-family: "Raleway", sans-serif;
                }

                .nav-link.active {
                    color: $active;
                    border-radius: $radius14;
                    border-bottom: 4px solid $active;
                    font-family: "Raleway", sans-serif;
                }
            }
        }

        .reservation {
            position: relative;
            padding: 20px 0 0 0;

            .personalizationBtn {
                position: absolute;
                top: -51px;
                right: 0;
            }

            h4 {
                margin: 0;
                padding: 0 0 20px 0;
                font-size: $text-lg;
                font-weight: $f-w-700;
                color: $text-blackgray-100;
                font-family: "Raleway", sans-serif;
            }
        }

        .avvisi {
            h6 {
                margin: 0;
                padding: 8px 12px;
                display: inline-flex;
                font-size: $test-sm;
                font-weight: $f-w-700;
                border-radius: $radius8;
                justify-content: flex-start;
                background: $text-gray-1002;
                text-transform: capitalize;
                font-family: "Raleway", sans-serif;
            }
        }

        .Idoneità {
            h6 {
                margin: 0;
                padding: 8px 12px;
                display: inline-flex;
                font-size: $test-sm;
                font-weight: $f-w-700;
                border-radius: $radius8;
                justify-content: flex-start;
                background: $text-gray-1002;
                font-family: "Raleway", sans-serif;
            }
        }
    }

    .date-error {
        border: 1px solid #dc3545;
    }
}