@import '../../assets/scss/variables.scss';
.AvailableTimeSlote {
    padding: 30px;
    position: relative;
    padding-bottom: 60px;
    a{
        font-size: $test-small-light;
        color: $text-gray-400;        
    }
    .showmap{
        padding: 20px;
        // display: block;
        // border-width: 1px 0 1px 0;
        // border-style: solid;
        // border-color: $text-black;
        // padding: 40px 0 0 0;
        // margin: 25px 0 0 0;
        .lefts{
            color: $text-gray-1004;            
            border-radius: 99px;
            padding: 6px 10px;
            margin-right: 20px;
            text-transform: uppercase;
            border: 1px solid $border6;
        }
        .rightf{
            position: absolute;
            top: 45px;
            right: 0;
            padding: 8px 15px;                
            font-size: $test-sm;
            background: $border4;  
            font-weight: $f-w-700;              
            color: $text-gray-1000;                
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
        }
        h2{                        
            margin: 0;
            padding: 0 0 0 0;
            font-size: $text-lg;
            font-weight: $f-w-700;
            color: $text-blackgray-100;
        }
        p{  
            margin: 0;
            padding: 16px 0 0 0;
            color: $text-blackgray-100;
            svg{
                margin-right: 10px;
            }
            a{
                font-size: $test-sm;                
                font-weight: $f-w-700;
                color: $text-blackgray-100;
                text-decoration: underline;                
            }
        }
        .divider{
            margin: 30px 0;
            border-top:1px solid $text-gray-1002;
        }
        h5{
            margin: 0;
            padding: 0 0 16px 0;
            font-weight: $f-w-700;
            color: $text-blackgray-100;
            font-size: $test-small-light;
        }
        button{
            width: 90px; height: 32px;
            padding: 0;
            border: 0; outline: 0;
            color: $primary;
            box-shadow: none !important; 
            border-radius: 99px;
            position: relative;
            line-height: 30px;
            font-weight: $f-w-400;
            background-color: $text-red-800;            
            svg{
                display: none;
                transform: translateY(-50%);
                position: absolute;
                top: 50%;
                left: 6px
            }
        }
        .dateBtn {
            width:140px; 
            position: relative;
        }
        .aciveDate{
            width:140px; 
            color: $text-red-50;
            background: $text-red-900;
            svg{
                display: inline-block !important;
                width: 16px;
            }
        }
       .sendSlots{
        .btn-primary{
            width: 100%;
            height: 40px;
            line-height: 21px;
        }
       }
        .btnActive{
            color: $text-red-50;
            background: $text-red-900;
            .tick{
                display: inline-block !important;
                width: 16px;
            }
        }
        .booked {
            border: 0;            
            border-radius: 99px;
            color: $text-gray-1000;
            background: $text-gray-1003;
            svg.booked{
                display: inline;
                width: 16px;
            }
        }
        .map{
            width: 100%;
            margin: 40px 0 0 0;
            border-radius: $radius1;
        }
       
        // h3{
        //     font-size: $text-lg;
        //     padding: 100px 0 15px 0;
        // }   
        // p{
        //     margin: 0;
        //     color: $text-gray-400;
        //     font-size: $test-small-light;
        //     strong{
        //         color: $text-gray-400;
        //         font-size: $test-small-light;
        //     }
        // }
    }
    // p{
    //     font-family: 'Arial', sans-serif;
    //     color: $text-gray-400;
    //     font-size: $test-sm;
    //     display: flex;
    //     align-items: center;
    //     margin: 0;
    //     i{font-style: italic;}
    // }
    // h4{
    //     font-size: $test-small;
    //     color: $text-gray-400;
    //     padding: 40px 0 25px 0;
    //     margin: 0;
    // }
    Button{
        font-weight: bold;
        padding: 10px 20px;
        margin: 0 10px 10px 0;
    }
    .booked{
        cursor: no-drop;
        opacity: .4;
    }
    .col-sm-4{
        .btn-primary{
            border: 0;
            width: 100%;
            padding: 10px 0;
            margin: 20px 0 0 0;
            font-size: $test-sm;
            font-weight: $f-w-700;            
            color: $text-gray-1007;
            border-radius: $radius3;
            background: $text-gray-1003;
            &:hover,&:active{
                border: 0 !important;
                border-radius: $radius3;
                background: $text-gray-1003;
            }
        }
    }
    .activeSlot{
        button{
        color: $text-red-50 !important;
        background: $text-red-900 !important;
        &:hover,&:active,&:focus{
            color: $text-red-50 !important;
            background: $text-red-900 !important;
        }
    }
    }
}