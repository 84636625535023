@import '../../assets/scss/variables.scss';
.EmployeeProfile {
    h2{
        margin: 0; padding: 30px 0;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
        font-family: 'Raleway', sans-serif;
    }
    .logoPreview{
        position: absolute;
        top:24px; left: auto; right: 15px;
    }
    .profileBottomBtn{
        position: absolute;
        bottom: 40px;
        right: 40px;
    }
    h6{                
        margin: 0;
        padding: 8px 12px;        
        font-size: $test-sm;
        font-weight: $f-w-400;
        display: inline-block;
        border-radius: $radius8;
        background: $text-gray-1002;        
        font-family: 'Raleway', sans-serif;
        strong{
            font-size: $test-sm;
            font-weight: $f-w-700;
            font-family: 'Raleway', sans-serif;
        }
    }
}