@import '../../assets/scss/variables.scss';
.RegisterDetails {
    padding: 0;
    position: relative;
    background: $text-black-500;    
    .customTabs{     
        .tabHeader{
            display: flex;
            flex-direction: column;
            padding: 20px 0 0 0;
            select::-ms-expand {
                display: none !important;
            }
            select{            
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
            }
            // aside{
            //     width: 10%;
            //     h2{
            //         font-family: 'Inter', sans-serif;
            //         font-weight: $f-w-400;
            //         font-size: $text-3xl;
            //         padding: 0 ; margin: 0;
            //        }
            // }
            
            .logo{
                display: flex;
                justify-content: center;
                padding: 30px 0;
            }
            .navOne{
                li{
                    &:first-child{
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }                        
                    }
                }
            }
            .navTwo{
                li{
                    &:first-child{
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }   
                    }
                    &:nth-child(2){
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }   
                    }
                }
            }
            .navThree{
                li{
                    &:first-child{
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }   
                    }
                    &:nth-child(2){
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }   
                    }
                    &:nth-child(3){
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }   
                    }
                }
            }
            .navFour{
                li{
                    &:first-child{
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }   
                    }
                    &:nth-child(2){
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }   
                    }
                    &:nth-child(3){
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }   
                    }
                    &:last-child{
                        &:after{
                            content: '';
                            width: 100%;
                            height: 8px;
                            background: $text-red-400;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            border-radius: 31px;
                        }   
                    }
                }
            }
            .nav-bar{
                width: 100%;
                ul{
                    margin: 0 auto;
                    padding: 0 0 30px 0;
                    display: flex;
                    justify-content: center; 
                li{
                    width: 170px;
                    // border-radius: $radius11;
                    text-align: center;
                    color: $text-white;
                    list-style-type: none;
                    padding: 0 0 7px 0;
                    margin: 0 12px 0 0;
                    position: relative;
                    border-bottom: 8px solid transparent;
                    &:last-child{
                        padding: 0; margin: 0;
                    }
                    &:after{
                        content: '';
                        width: 100%;
                        height: 8px;
                        position: absolute;
                        left:0; bottom: -8px;
                        background: $text-white;
                        border-radius: $radius11;
                    }
                }
                .active{
                    border-color: $text-red-400;
                }
                
            }
            .progress{
                width: 60%;
                height: 11px;
                margin: 0 auto;            
                border-radius: 0;
            }
        }
      
        }       
        .show{
            display: block;
        }
        .hide{
            display: none;
        }
        .content{
             .customBtn{
                border-radius: $radius3;
                padding: 0.65rem 2rem;
                font-weight: $f-w-600;
                font-size: $test-medium-xlight;
                font-family: 'Raleway', sans-serif;                
             }   
             .oulineBnt{
                border-radius: $radius3;
                padding: 0.65rem 2rem;
                font-weight: $f-w-600;
                font-size: $test-medium-xlight;
                font-family: 'Raleway', sans-serif;  
             }                   
        }
        .tab1{         
            padding: 36px 0 30px 0;   
            width: 420px;
            margin: 0 auto;            
            .innerTab1{
                width: 100%;
                padding: 40px;
                background: $text-white;
                border-radius: $radius7;
                position: relative;
                .topLeft{
                    position: absolute;
                    left: -73px;
                    top: 16px;
                }
                .bottomRight{
                    position: absolute;
                    right: -68px;
                    bottom: -17px;                   
                }
            }
            h6{
                svg{
                    margin-right: 10px;
                }                
                display: flex;                                
                color: $border4;                
                margin: 15px 0 0 0;
                font-size:$test-sm;
                align-items: center;
                line-height: 1.2rem;                  
                font-family: 'Raleway', sans-serif;                                              
             }  
            //  .row{
            //     padding: 100px 0 0 0;
            //  }
         }
         .tab2{
            padding: 36px 0 30px 0 ;
            .screen1{                 
                width: 700px;
                padding: 40px;        
                margin: 0 auto;                
                background-color: $text-white;    
                position: relative;
                border-radius: $radius7;
                .topLeft{
                    position: absolute;
                    left: -73px;
                    top: 16px;
                }
                .bottomRight{
                    position: absolute;
                    right: -68px;
                    bottom: -17px;
                }
                // .row{
                //     &:first-child{
                //         padding: 100px 0 0 0;    
                //     }
                //     padding: 50px 0 0 0;
                // }
            }
            .screen2{              
                    h3{
                        padding: 100px 0 15px 0;
                        font-size: $test-base;                        
                        font-weight: $f-w-600;
                        color: $text-black-500;
                        font-family: 'Raleway', sans-serif;
                    }
                    h6{
                        padding: 20px 0 15px 0;
                        font-size: $test-base;                        
                        font-weight: $f-w-600;
                        color: $text-black-500;
                        font-family: 'Raleway', sans-serif;
                    }                 
                    .customBtn{
                        margin: 50px 0 0 0;
                    }                
            }
         }
         .tab3{
            padding: 36px 0 30px 0;
            .screen2{
                width: 700px;
                padding: 40px;        
                margin: 0 auto;                
                background-color: $text-white;    
                border-radius: $radius7;
                position: relative;
                .topLeft{
                    position: absolute;
                    left: -73px;
                    top: 16px;
                }
                .bottomRight{
                    position: absolute;
                    right: -68px;
                    bottom: -17px;
                }
            }            
         }
         .tab4{
            padding: 36px 0 0 0;
            .policy{
                width: 700px;
                padding: 40px;
                margin: 0 auto 30px auto;
                background: $graybglight;
                border-radius: $radius4;                
                .policyDetails{
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;                
                font-weight: $f-w-600;
                color: $text-black;
                font-size: $test-base;
                background: $graybglight;
                font-family: 'Raleway', sans-serif;                               
                overflow-x: hidden;
                overflow-y: auto;
                
                h3{
                    margin: 0;
                    padding: 0 0 50px 0;
                    font-size: $test-base;
                    font-weight: $f-w-600;
                }
                h4{
                    margin: 0;
                    padding: 0 0 15px 0;                    
                    font-size: $test-base;
                    font-weight: $f-w-600;
                }
                p{  
                    margin: 0;
                    padding: 0 0 50px 0;
                    font-size: $test-base;
                    font-weight: $f-w-600;
                }
              
                ol{
                    li{
                        border: 0;
                        padding: 0 0 15px 0;                         
                        background: transparent;
                        p{  
                            margin: 0;
                            padding: 15px 0 0 0;
                            font-size: $test-base;
                            font-weight: $f-w-600;
                        }
                    }
                }
                  }
            }
         }
    }
    .registerSuccess{
        width: 100%;
        display: flex;
        align-self: center;
        justify-content: center;
        .container{
            width: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
           img{
                width: 100px;
            }
            h2{            
                margin: 0;
                padding: 25px 0 0 0;    
                font-weight: $f-w-600;
                font-size: $test-medium;
                color: $text-black-600;
                font-family: 'Raleway', sans-serif;
            }
            p{
                color: $text-gray-300;
                padding: 15px 0 30px 0; margin: 0;                
                font-family: 'Raleway', sans-serif;
            }
            Button{
                color: $text-white;                            
                padding: 0.65rem 2rem;                
                font-size: $test-medium-xlight;
                font-family: 'Raleway', sans-serif;
            }
        }
    }
    .bottom{
        height: 72px;
        display: flex;
        flex-direction: row;
        align-items: center;        
        background: $text-gray-900;
        .content{            
            width: 700px;
            margin: 0 auto;
            .form-check{
                label{                    
                    font-size: $test-sm;
                    color: $text-black-500;
                    font-family: 'Raleway', sans-serif;
                }
            }
            button{                
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px; width: 150px;
                font-size: $test-sm;
                letter-spacing: 1px;
                font-weight: $f-w-700;
                border-radius: $radius3;
                box-shadow: $shadow11;
                svg{
                    left: 5px;
                    position: relative;                    
                }
            }
            .disableRegisterBtn{
                    button{                
                        color: $text-gray-1007;
                        background-color: $text-gray-1003;
                        border-color: $text-gray-1003;
                        box-shadow: none;
                        path{
                            fill: $text-gray-1007;
                        }
                    }
               }
        }
    }
    .lastTabs{
        height: auto !important;
        padding: 20px 0 0 0;
        .row{
            margin-bottom: 20px;
        }
        .div{
            margin-bottom: 20px;
        }
    }
}