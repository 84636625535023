// font color
$text-current:#3C3C3C;
$text-white:#FFFFFF;
$text-black:#000000;
$primary:#BA0F31;
$seconday:#F6BDC7;
$tertiary:#9FCDE5;
$quaternary:#FA284B;
$active:#2264D1;
$indigo: #5C6BC0;
$pink:#EC407A;
$teal:#069697;
$orange:#FB8200;
$lightBlue:#F5F8FF;
$blue:#2979FF;
$green:#87C289;
$text-black-500:#080D2C;
$text-black-600:#101828;
$text-black-700:#111928;
$text-black-800:#242331;
$text-black-900:#282938;
$text-black-1000:#00273C;
$text-black-1001:#333333;
$text-black-1002:#293854; 
$text-black-1003:#19191D;
$text-gray-1008:#90A0B7;
$text-gray-1007:#9696A0;
$text-gray-1006:#637381;
$text-gray-1005:#19191D;
$text-gray-1004:#4A4B57;
$text-gray-1003:#EDEDF0;
$text-gray-1002:#E1E1E3;
$text-gray-1001:#787885;
$text-gray-1000:#5A5B6A;
$text-gray-900:#F7F7FA;
$text-gray-800:#8F8F8F;
$text-gray-700:#BAB9B9;
$text-gray-600:#797979;
$text-gray-500:#E3E3E3;
$text-gray-400:#5E5E5E;
$text-gray-300:#667085;
$text-gray-200:#D0D5DD;
$text-gray-100:#EAECF0;
$text-red-50:#FEF2F1;
$text-red-100:#DD5761;
$text-red-200:#FAA9A3;
$text-red-300:#FDDCDA;
$text-red-400:#E4889A;
$text-red-500:#8B0C26;
$text-red-600:#E31A42;
$text-red-700:#AD0E2E;
$text-red-800:#FEE8E7;
$text-red-900:#EE254D;
$text-blackgray-300:#3A3A44;
$text-blackgray-200:#192A3E;
$text-blackgray-100:#292A31;
$text-blue-50:#133774;
$text-blue-100:#000001;
$text-blue-200:#1B4EA3;
$text-blue-300:#EBF2FF;
$text-teal-100:#33A9A9;
$text-indigo-70: #4C589E;

///background
$lightGray:#F4F4F4;
$graybg:#E7E7E7;
$graybglight:#F2F2F2;
$graybgbold:#F5F5F5;
$graybgdarks:#DEE5EF;
$graybgdark:#535353;
$indigoBg:#F7F8FC;
$pinkBg:#FFF7FA;
$tealBg:#F0FAFA;
$orangeBg:#FFF6ED;
$adminBg:#FFFEF9;


// border
$border1:#F0F0F0;
$border2:#D5D5D5;
$border3:#353535;
$border4:#D2D2D6;
$border5: #9DC2FF;
$border6:#B4B4BB;
$border7:#43A047;
$border8:#D9D9D9;
// font size
$vvsmall:0.625rem; // 10px
$test-xs:.5rem; 
$test-sm:.875rem; // 14px
$test-base:1rem; // Regular font size
$test-small:1.125rem; // 18px
$test-small-light:1.25rem; // 20px
$test-small-big:1.375rem; // 22px

$text-small:0.75rem; // 12px
$test-medium:1.875rem; // 30px
$test-medium-xlight:1.341rem; //21.45
$test-medium-small:2rem; // 32px
$test-medium-xsmall:2.125rem; // 34px
$test-medium-large:2.25rem; // 36px
$text-lg:1.5rem; // 24px
$text-xl:1.8rem;  // 28.8px
$text-3xl:2.5rem;  // 40px
$text-3xl-light:2.75rem;  // 44px
$text-3xl-small:3rem; // 48px
$text-4xl:3.75rem;  // 60px
$text-7xl:6.75rem;  // 108px

$f-w-300:300;
$f-w-400:400;
$f-w-500:500;
$f-w-600:600;
$f-w-700:700;
$f-w-800:800;
$f-w-900:900;

$shadow1:0px 2px 6px rgba(0,0,0,.1);
$shadow2:0px 21px 20px rgba(187,15,49, .2);
$shadow3:0px 2px 13px rgba(0,0,0,.1);
$shadow5:0 20px 95px #C9CBCC;
$shadow6:0px 2px 17px rgba(0, 0, 0, 0.2);
$shadow7: 0px 4px 8px 0px #3A3A4429;
$shadow8:0px 2px 21px 10px rgba(90, 91, 106, 0.0705882353);
$shadow9:0px 2px 10px 5px #5A5B6A12;
$shadow10:0px 2px 10px 7px rgba(90, 91, 106, 0.0705882353);
$shadow11:0 20px 50px 0px #BA0F319C;
$shadow12: 0px 8px 16px 0px #5A5B6A29;
$shadow13:0px 2px 4px 0px #3A3A4433;

// border radius
$radius1:20px;
$radius2:8px;
$radius3:12px;
$radius4:16px;
$radius5:10px;
$radius6:30px;
$radius7:22px;
$radius8:6px;
$radius9:5px;
$radius10:18px;
$radius11:31px;
$radius12:4px;
$radius13:99px;
$radius14:4px;

$text-shdow1:0px 2px 2px rgba(0,0,0,.5);