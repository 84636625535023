@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

// font-family: 'Raleway', sans-serif;
// font-family: 'Inter', sans-serif;
// font-family: 'Inter', sans-serif;
// font-family: 'Work Sans', sans-serif;
// font-family: 'Poppins', sans-serif;
// font-family: 'Arial', sans-serif;
// font-family: 'Nunito', sans-serif;
@import "../scss/variables.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

// @import '../../../node_modules/bootstrap/scss/bootstrap.scss';
:root {
  font-size: 16px;
}

$railway: "Raleway";
$font-family-1: "Source Code Pro", Courier, mono;

.btn .btn-primary {
  background: $primary;
  padding: 0.575rem 0.75rem;
}

label.form-label {
  margin: 0 0 3px 0;
  font-size: $test-sm;
  color: $text-gray-1001;
  font-family: "Raleway", sans-serif;
  border-color: $text-gray-300;
  // text-transform: capitalize;
}

.form-control {
  font-family: "Raleway", sans-serif;
  border-color: $border6;
  color: $text-gray-300;
  padding: 0.475rem 0.75rem;
  border-radius: $radius3;

  &:hover,
  &.active,
  &:focus {
    border-color: $text-gray-300;
    box-shadow: none;
  }
}

.show.dropdown {
  .btn:first-child:active {
    box-shadow: none;
    color: $text-gray-300;
    background-color: $text-white;
    border-color: $text-gray-300;
  }

  button {
    // display: flex;
    // align-items: center;
    color: $text-gray-300;
    // justify-content: space-between;
    border-color: $text-gray-300;
    background-color: $text-white;

    &:hover,
    &:focus,
    &.active,
    &:focus-visible {
      box-shadow: none;
      color: $text-gray-300;
      background-color: $text-white;
      border-color: $text-gray-300;
    }
  }
}

.dropdown {
  width: 100%;

  .btn:first-child:active {
    box-shadow: none;
    color: $text-gray-300;
    background-color: $text-white;
    border-color: $text-gray-300;
  }

  button {
    width: 100%;
    text-align: left;
    // display: flex;
    // align-items: center;
    color: $text-gray-300;
    font-size: $test-base;
    padding: 0.575rem 0.75rem;
    border-color: $text-gray-300;
    font-family: "Raleway", sans-serif;
    // justify-content: space-between;
    background-color: $text-white;

    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      box-shadow: none;
      color: $text-gray-300;
      background-color: $text-white;
      border-color: $text-gray-300;
    }

    &:after {
      position: absolute;
      right: 20px;
      top: 50%;
    }
  }
}

body {
  text-align: left;
  line-height: 1.5;
  font-weight: $f-w-400;
  font-size: $test-base;
  color: $text-current;
  box-sizing: border-box;
  background-color: $text-white;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.adminBtnPrimary {
  .btn-primary {
    color: $lightBlue;
    background-color: $blue;
    font-weight: $f-w-700;
    font-family: "Raleway", sans-serif;
    border: none;
    box-shadow: 0px 4px 8px 0px #2979ff33;
    &:hover,
    &:active {
      outline: 0;
      color: $lightBlue;
      background-color: $blue !important;
    }
  }

  .btn-outline-primary {
    color: $active;
    background-color: $text-white;
    border: 2px solid $border5;
    font-weight: $f-w-700;
    font-family: "Raleway", sans-serif;

    &:hover,
    &:active {
      outline: 0;
      color: $active;
      background-color: $text-white !important;
      border: 2px solid $border5 !important;
    }
  }

  .btn-outline-secondary {
    color: $primary;
    background-color: $text-white;
    border: 2px solid $text-red-200;
    font-weight: $f-w-700;
    border-radius: $radius3;

    &:hover,
    &:active {
      outline: 0;
      color: $primary;
      background-color: $text-white !important;
      border: 2px solid $text-red-200 !important;
    }
  }
}

.switch.btn {
  border: 0;
  width: 200px;
  border-radius: $radius5;
  background: $text-white;
  min-width: 200px !important;
  box-shadow: $shadow6;

  .switch-group {
    left: 0 !important;
    width: 100%;
  }

  .switch-on {
    width: 50%;
    color: $text-gray-700;
    font-weight: $f-w-500;
    background-color: $text-white;
    border-color: $text-white;
    font-family: "Raleway", sans-serif;
  }

  .switch-off {
    width: 50%;
    width: 50%;
    color: $text-gray-700;
    font-weight: $f-w-500;
    background-color: $text-white;
    border-color: $text-white;
    font-family: "Raleway", sans-serif;
  }

  .switch-handle {
    display: none;
  }

  &:focus,
  &:active,
  &:hover,
  &:focus-visible {
    outline: 0;
    background-color: transparent;
    border-color: transparent;
  }
}

.switch.btn.off.btn-success {
  .btn-success {
    top: 2px;
    height: 90%;
    width: 48.5%;
    color: $text-white;
    border-radius: $radius5;
    background-color: $text-black-500;
    border-color: $text-black-500;
  }
}

.switch.btn.on.btn-danger {
  .btn-danger {
    left: 2px;
    top: 2px;
    height: 90%;
    width: 48%;
    color: $text-white;
    border-radius: $radius5;
    background-color: $text-black-500;
    border-color: $text-black-500;
  }
}

.imageUpload {
  position: relative;

  .logoPreview {
    width: 50px;
    height: 50px;
    border: 1px solid $border5;

    position: absolute;
    top: 29px;
    right: 0;

    img {
      width: 100%;
      height: 100%;
    }

    span {
      cursor: pointer;
      position: absolute;
      top: -3px;
      left: 38px;
    }
  }
}

// modal
.userModalBtn {
  .btn-primary {
    background-color: $primary !important;
    border-color: $primary !important;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }

  .btn-outline-primary {
    box-shadow: none;
    color: $primary !important;
    border: 2px solid $text-red-200 !important;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      color: $primary !important;
      border: 2px solid $text-red-200 !important;
    }
  }
}

.modal {
  .modal-dialog {
    width: 450px;

    .modal-content {
      border-radius: $radius7;

      .modal-body {
        padding: 28px 24px 28px 24px;

        h2 {
          margin: 0;
          padding: 0 0 20px 0;
          text-align: center;
          font-size: $text-lg;
          line-height: 2.2rem;
          font-weight: $f-w-700;
          color: $text-blackgray-100;
          font-family: "Raleway", sans-serif;
        }

        h6 {
          margin: 0;
          padding: 0 0 30px 0;
          font-family: "Raleway", sans-serif;
        }

        button {
          width: 100%;
          font-size: $test-sm;
          font-weight: $f-w-700;
          border-radius: $radius3;
        }
      }
    }
  }
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: $text-white !important;
}

.reactCustomDatePicker {
  .react-datepicker__day:hover {
    border-radius: 50px;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--selected {
    background-color: $border5;
    border-radius: 50px;
  }

  .react-datepicker__day-name {
    color: $text-gray-1007 !important;
    font-weight: $f-w-700 !important;
    font-family: "Raleway", sans-serif !important;
  }

  .react-datepicker__day {
    color: $text-black-1003;
    font-family: "Raleway", sans-serif !important;
  }

  .react-datepicker__header > div {
    // display: flex;
    justify-content: space-between !important;
    // border: 1px solid red;
    // padding: 0 10px;
  }

  .react-datepicker__header > div:first-child {
    button:first-child {
      background: $text-white url("../images/right-arrow.png") no-repeat center center/7px;
      transform: rotate(180deg);
      // text-indent: 10000px;
      // position: relative; left: -15px;
      color: #fff;
      font-size: 0;
    }

    // select:first-child{
    //   // border: 1px solid red;
    // }
    button:disabled {
      opacity: 0.5;
    }

    button:last-child {
      background: $text-white url("../images/right-arrow.png") no-repeat center right/7px;
      // transform: rotate(180deg);
      // text-indent: 10000px;
      // position: relative; right: -15px;
      color: #fff;
      font-size: 0;
    }
  }

  .react-datepicker__header {
    button {
      border: 0;
      outline: 0;
      background: transparent;
    }
  }

  select::-ms-expand {
    display: none !important;
  }

  select {
    text-align: center;
    padding: 0 15px 0 0;
    border: 0;
    outline: 0;
    background: $text-white url(../images/down_arrow1.png) no-repeat right center/7px;
    font-size: $test-sm;
    font-weight: $f-w-700;
    color: $text-gray-1004;
    font-family: "Raleway", sans-serif;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:hover,
    &:active,
    &:focus {
      border: 0;
      outline: 0;
    }
  }

  .react-datepicker__header {
    background-color: $text-white;
    border-top: 0;
    border-bottom: 0;
  }

  .react-datepicker__day-names {
    border-top: 1px solid $text-gray-1003;
  }
}
// .reactCustomDatePicker {
//   input:disbled{
//     background-color: red !important;
//   }
// }
.form-control:disabled {
  background-color: $text-gray-1003;
  opacity: 1;
  border-color: $text-gray-1003;
}
// .form-control:hover, .form-control.active, .form-control:focus {
//   border-color: #667085;
//   box-shadow: none;
// }
// .form-control:hover, .form-control.active, .form-control:focus {
//   border-color: $text-gray-1003 !important;
//   box-shadow: none;
// }
.reactCustomDatePicker .react-datepicker__input-container input:disabled {
  background: $text-gray-1003 url("../images/bottom_arrow.png") no-repeat 95% center !important;
  border-color: $text-gray-1003;
}
.reactCustomDatePickerFilterAdmin {
  .react-datepicker-wrapper {
    input {
      border: 0;
      outline: 0;
      background: $text-gray-1003 url("../images/bottom_arrow.png") no-repeat 95% center !important;

      &:focus,
      &:active,
      &:focus {
        border: 0;
        outline: 0;
      }
    }
  }

  .react-datepicker__day:hover {
    border-radius: 50px;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--selected {
    background-color: $border5;
    border-radius: 50px;
  }

  .react-datepicker__current-month {
    margin: 7px 0 10px 0;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    position: absolute;
    top: 8px;
  }

  .react-datepicker__day-name {
    color: $text-gray-1007 !important;
    font-weight: $f-w-700 !important;
    font-family: "Raleway", sans-serif !important;
  }

  .react-datepicker__day {
    color: $text-black-1003;
    font-family: "Raleway", sans-serif !important;
  }

  .react-datepicker__header > div {
    // display: flex;
    justify-content: space-between !important;
    // border: 1px solid red;
    // padding: 0 10px;
  }

  .react-datepicker__header > div:first-child {
    button:first-child {
      background: $text-white url("../images/right-arrow.png") no-repeat center center/7px;
      transform: rotate(180deg);
      // text-indent: 10000px;
      // position: relative; left: -15px;
      color: #fff;
      font-size: 0;
    }

    // select:first-child{
    //   // border: 1px solid red;
    // }
    button:disabled {
      opacity: 0.5;
    }

    button:last-child {
      background: $text-white url("../images/right-arrow.png") no-repeat center right/7px;
      // transform: rotate(180deg);
      // text-indent: 10000px;
      // position: relative; right: -15px;
      color: #fff;
      font-size: 0;
    }
  }

  .react-datepicker__header {
    button {
      border: 0;
      outline: 0;
      background: transparent;
    }
  }

  select::-ms-expand {
    display: none !important;
  }

  select {
    text-align: center;
    padding: 0 15px 0 0;
    border: 0;
    outline: 0;
    background: $text-white url(../images/down_arrow1.png) no-repeat right center/7px;
    font-size: $test-sm;
    font-weight: $f-w-700;
    color: $text-gray-1004;
    font-family: "Raleway", sans-serif;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:hover,
    &:active,
    &:focus {
      border: 0;
      outline: 0;
    }
  }

  .react-datepicker__header {
    background-color: $text-white;
    border-top: 0;
    border-bottom: 0;
  }

  .react-datepicker__day-names {
    border-top: 1px solid $text-gray-1003;
  }
}

.reactCustomDatePickerAdmin {
  .react-datepicker__day:hover {
    border-radius: 50px;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--selected {
    background-color: $border5;
    border-radius: 50px;
  }

  .react-datepicker__current-month {
    margin: 7px 0 10px 0;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    position: absolute;
    top: 8px;
  }

  .react-datepicker__day-name {
    color: $text-gray-1007 !important;
    font-weight: $f-w-700 !important;
    font-family: "Raleway", sans-serif !important;
  }

  .react-datepicker__day {
    color: $text-black-1003;
    font-family: "Raleway", sans-serif !important;
  }

  .react-datepicker__header > div {
    // display: flex;
    justify-content: space-between !important;
    // border: 1px solid red;
    // padding: 0 10px;
  }

  .react-datepicker__header > div:first-child {
    button:first-child {
      background: $text-white url("../images/right-arrow.png") no-repeat center center/7px;
      transform: rotate(180deg);
      // text-indent: 10000px;
      // position: relative; left: -15px;
      color: #fff;
      font-size: 0;
    }

    // select:first-child{
    //   // border: 1px solid red;
    // }
    button:disabled {
      opacity: 0.5;
    }

    button:last-child {
      background: $text-white url("../images/right-arrow.png") no-repeat center right/7px;
      // transform: rotate(180deg);
      // text-indent: 10000px;
      // position: relative; right: -15px;
      color: #fff;
      font-size: 0;
    }
  }

  .react-datepicker__header {
    button {
      border: 0;
      outline: 0;
      background: transparent;
    }
  }

  select::-ms-expand {
    display: none !important;
  }

  select {
    text-align: center;
    padding: 0 15px 0 0;
    border: 0;
    outline: 0;
    background: $text-white url(../images/down_arrow1.png) no-repeat right center/7px;
    font-size: $test-sm;
    font-weight: $f-w-700;
    color: $text-gray-1004;
    font-family: "Raleway", sans-serif;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:hover,
    &:active,
    &:focus {
      border: 0;
      outline: 0;
    }
  }

  .react-datepicker__header {
    background-color: $text-white;
    border-top: 0;
    border-bottom: 0;
  }

  .react-datepicker__day-names {
    border-top: 1px solid $text-gray-1003;
  }
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    width: 100%;
    font-family: "Raleway", sans-serif;
    border: 1px solid $border6;
    color: $text-gray-300;
    padding: 0.475rem 0.75rem;
    border-radius: $radius3;
    background: url(../../assets/images/bottom_arrow.png) #ffffff no-repeat 95% 15px;

    &:hover,
    &.active,
    &:focus {
      border-color: $text-gray-300;
      box-shadow: none;
    }
  }
}

.customAdminTabs {
  .tabHeader {
    .nav-bar {
      ul {
        display: flex;
        flex-direction: row;
        margin: 0 0 16px 0;
        padding: 0;

        li {
          list-style-type: none;
          padding: 0.5rem 1rem;
          font-weight: $f-w-700;
          border-bottom: 4px solid transparent;
          border-radius: $radius12;
          letter-spacing: 1px;
        }

        .active {
          color: $active;
          font-weight: $f-w-700;
          border-radius: $radius12;
          border-bottom: 4px solid $active;
          font-family: "Raleway", sans-serif;
        }
      }
    }
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }
}

.delete {
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
  margin-left: 15px;

  &:hover,
  &:active {
    outline: 0;
    border: 0;
    background-color: transparent !important;
  }
}

.edit {
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;

  &:hover,
  &:active {
    outline: 0;
    border: 0;
    background-color: transparent !important;
  }
}

.fullWidth {
  width: 100%;
}

.pagination {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  font-size: $text-small;
  color: $text-black-1003;
  justify-content: flex-end;
  font-family: "Raleway", sans-serif;

  .prev {
    cursor: pointer;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0 20px 0 25px;
    background: transparent;
  }

  .next {
    cursor: pointer;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    background: transparent;
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.alert-success {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
  max-width: 500px;
  z-index: 9999;

  button {
    border: 0;
    outline: 0;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}

.alert-danger {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
  max-width: 500px;
  z-index: 999999;

  button {
    border: 0;
    outline: 0;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}

.adminCustomToggle {
  .form-check {
    label {
      position: relative;
      top: 4px;
    }
    .form-check-input {
      border: 0;
      outline: 0;
      background-color: $text-gray-1002;
      width: 40px;
      height: 24px;
      box-shadow: none;
      background-size: 72%;
      border-radius: 99px;

      &:checked {
        background-color: $border5;
      }
    }

    .form-check-label {
      margin-left: 10px;
      font-size: $test-sm;
      font-weight: $f-w-700;
      color: $text-gray-1001;
    }
  }

  .form-switch .form-check-input:checked {
    background-position: right center;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(34, 100, 209, 1)'/%3e%3c/svg%3e");
  }

  .form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(34, 100, 209, 1)'/%3e%3c/svg%3e");
  }
}

input[type="text"],
input[type="email"] {
  &::-webkit-input-placeholder {
    color: $text-gray-1007;
  }
  &::-moz-placeholder {
    color: $text-gray-1007;
  }
  &::-ms-input-placeholder {
    color: $text-gray-1007;
  }
}

.form-select {
  box-shadow: none !important;
  border-radius: $radius3;
  background: url(../../assets/images/bottom_arrow.png) #ffffff no-repeat 98% 15px !important;

  &:hover,
  &.active,
  &:focus {
    border-color: $text-gray-300;
    box-shadow: none;
  }
}

select:disabled {
  background: #e9ecef !important;
}

.noOfChar {
  margin: 0;
  top: -15px;
  display: flex;
  position: relative;
  font-size: $test-sm;
  color: $text-gray-1001;
  justify-content: flex-end;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}

// .BreadCrum li a > a::first-letter{
//    border: 1px solid green;
// }

@media (min-width: 768px) and (max-width: 1023px) {
  .eligibilityQuiz {
    height: 100vh !important;
  }
}

@media (max-width: 1024px) {
  // .Header .innerContainer .generalLogo{left: 11% !important;}
  .Header .innerContainer .profileSettings .profile {
    margin-left: 0 !important;
  }

  .Header {
    height: auto !important;
    .profileSettings {
      position: fixed;
      top: -11px;
      right: 0;
      width: 50%;
    }
  }
  .GeneraliHeader {
    height: 75px;
  }

  .needhelp button {
    width: 100% !important;
  }

  .Login {
    .innerLogin {
      width: 100%;

      .loginCard {
        width: 90%;
      }

      // h2{
      //     padding: 0 0 10px 0;
      // }
      // p{
      //     padding: 15px 0;
      // }
    }
  }
  .Header {
    .logos {
      display: none;
    }
  }
  .Header {
    // height: auto;
    padding: 10px 0;

    .logo {
      display: none;
    }

    .closeIcons {
      left: 12% !important;
    }

    .generalLogo {
      // left: 11% !important;
      // top: 50%;
      // transform: translateX(-50%) !important;
      // transform: translateY(0%);
      // width: 150px !important;
    }

    .mlogo {
      width: 30px;
      display: block !important;
      position: absolute;
      top: 23px;
      left: 50%;
      transform: translateX(-50%);
    }

    button {
      &:focus,
      &:active,
      &:focus-visible {
        box-shadow: none;
      }
    }

    .MenuBar {
      .navbar-nav {
        a {
          &:nth-last-child(1),
          &:nth-last-child(2) {
            display: block;
          }
        }
      }
    }

    .profileSettings {
      // align-items: flex-start !important;
      // justify-content: flex-end !important;
      .nav {
        display: none;
      }

      .profile {
        button {
          display: flex;
          justify-content: flex-end;
        }

        .dropdown-menu {
          margin: 15px 0 0 0 !important;
        }
      }
    }
  }

  .AvailableSlot {
    .img {
      width: 100%;
    }

    .fillTest {
      align-items: center;

      h6 {
        padding: 30px 0 0 0;
      }
    }

    p {
      width: 100%;
    }
  }

  .BookDonation {
    h2,
    p {
      text-align: center;
    }
  }

  .Quiz {
    .startquiz {
      .content {
        width: 80% !important;
        .card {
          width: 100% !important;
        }
      }
    }
    .eligibilityQuiz {
      .innerScreen {
        width: 90% !important;
      }
      .bottom {
        .content {
          width: 90% !important;
        }
      }
    }
    .success {
      .innerContent {
        width: 85% !important;
      }
    }
  }

  .UserRegister {
    .screen4 {
      p {
        padding: 0 !important;
      }

      svg {
        left: -100px !important;
      }
    }
  }

  .AvailableTimeSlote {
    .directions {
      margin: 20px 0 0 0;
    }
  }

  .BookSlot {
    button {
      margin: 30px 0 0 0;
    }
  }

  .BookNow {
    .banner {
      margin: 50px 0 50px 0 !important;
      width: 100%;
      background-size: 100% !important;
      height: 230px !important;
    }

    .card {
      padding: 0 !important;
      border-width: 0 !important;
    }

    .donnerDetails {
      p {
        padding: 0 !important;
      }
    }
  }

  .HomeNavBar {
    height: auto !important;
    padding: 10px 0;

    .logo {
      display: none;
    }

    .mlogo {
      width: 30px;
      display: block !important;
      position: absolute;
      top: 23px;
      left: 50%;
      transform: translateX(-50%);
    }

    .topBarLeft {
      .navbar-toggler {
        &:focus,
        &:active,
        &:hover {
          box-shadow: none;
          outline: 0;
        }
      }

      .navbar-collapse {
        a {
          &:nth-last-child(1),
          &:nth-last-child(2),
          &:nth-last-child(3) {
            display: block !important;
          }
        }
      }
    }

    .topBarRight {
      align-items: flex-start !important;

      // img{
      //     position: relative; top: 12px;
      //     width: 30px !important;
      // }
      .nav {
        display: none;
      }
    }
  }

  .LandingPage {
    .homeBanner {
      height: 218px !important;
    }
  }

  .DonateNow {
    .card {
      width: 100%;
    }
  }

  .Reservations {
    .carousel-root {
      .carousel-slider {
        .slider {
          width: 100%;
          margin: 0;
          padding: 0;

          .slide {
            margin-right: 0;
          }
        }
      }
    }
  }

  .Events {
    .carousel-root {
      .carousel-slider {
        .slider {
          width: 100%;
          margin: 0;
          padding: 0;

          .slide {
            margin-right: 0;
          }
        }
      }
    }
  }

  .Seminar {
    .carousel-root {
      .carousel-slider {
        .slider {
          width: 100%;
          margin: 0;
          padding: 0;

          .slide {
            margin-right: 0;
          }
        }
      }
    }
  }

  .Footer {
    .socialMedia {
      justify-content: flex-start;
    }

    .needhelp {
      align-items: flex-start;

      svg {
        margin-left: 26px;
      }
    }
  }

  .RegisterDetails {
    .customTabs {
      .tab1 {
        width: 100% !important;
      }
      .tab2 {
        .screen1 {
          width: 100% !important;
        }
      }
      .tab3 {
        .screen2 {
          width: 100% !important;
        }
      }
      .tab4 {
        .policy {
          width: 100% !important;
        }
      }
    }
  }

  .GuestRegister {
    width: 100%;
    height: 100%;

    .customTabs {
      width: 100% !important;
    }

    .tab1 {
      width: 100% !important;
    }

    .tab2 {
      .screen1 {
        width: 100% !important;
      }
    }

    .screen2 {
      width: 100% !important;
    }

    .tab3 {
      width: 100% !important;
    }

    .tab4 {
      .policy {
        width: 100% !important;
      }
    }

    .bottom {
      // width: 100%;
      // position: absolute;
      // bottom: 0; left: 0;
    }

    .bottomRight,
    .topLeft {
      display: none;
    }

    .eligibilityQuiz {
      .screen1 {
        .innerScren1 {
          width: 100% !important;
        }
      }
    }

    .registerSuccess {
      .container {
        width: 100% !important;
      }
    }

    .booking-success {
      .slotDetails {
        width: 80% !important;
      }
    }

    .booking-failer {
      .failerDetails {
        width: 100% !important;
      }
    }
  }

  .FindPlace {
    .showMap {
      .mapList {
        height: auto !important;
      }
      .map {
        height: 500px;
      }
    }
  }

  // admin
  .AdminLayout {
    flex-direction: column !important;

    .AdminSidebar {
      width: 100%;
      height: auto;
      clip-path: none;
      // flex-direction: row;
      align-items: self-start;

      h2 {
        display: none;
      }

      .AdminMenuBar {
        width: 100%;
        .menu-md {
          display: none;
        }

        .navbar {
          display: flex;
        }
      }
    }

    .innerContent {
      width: 100% !important;

      .customContainer {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }
    }
  }

  .Dashboard {
    h6 {
      display: flex;
      justify-content: center;
    }

    .CardList {
      // padding: 0 0 50px 0;
      .list-group-item {
        text-align: center;
      }
    }
  }
  .AddDonationCenter {
    .adminBtnPrimary {
      button {
        position: relative !important;
        right: 0 !important;
        bottom: 0 !important;
      }
    }
  }
  .CompanyProfile {
    .mobEventContactPerson {
      top: 0 !important;
    }
    .logoEventPreview,
    .logoWebinarPreview {
      display: flex !important;
      top: 0 !important;
      left: 0 !important;
      position: relative !important;
      margin-bottom: 50px;
    }
  }
}

// .AdminLayout{
//     flex-direction: column;
//     .AdminSidebar{
//         width: 100%;
//         height: auto;
//         flex-direction: row;
//         align-items: self-start;
//     .AdminMenuBar{
//         .menu-md{
//             display: none;
//         }
//     }
// }
// .innerContent{
//     width: 100%;
// }

// }
