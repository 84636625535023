@import '../../assets/scss/variables.scss';

.AvailableSlot {
    .AvailableSlotBg{
        padding: 0 0 50px 0;
        background-size: contain;
        background: url(../../assets/images/heart1.png) no-repeat right top;
        position: relative;
        width: 100%;
        height: 100%;
    }

    h2 {
        text-transform: capitalize;
        margin: 0;
        padding: 50px 0 20px 0;
        font-size: $text-4xl;
        font-weight: $f-w-700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .AvailableSlotCurrentDay {
        position: absolute;
        right: 80px;
        top: 40px;
        font-size: 48px;
        color: $text-current;
        width: fit-content;
        font-family: "Raleway", sans-serif;
        font-weight: 700;
    }

    p {
        width: 450px;
        font-weight: $f-w-400;
        padding: 0 0 30px 0;
    }

    // .card{
    //     box-shadow: $shadow1;
    //     border: 0;
    //     p{
    //         font-size: $test-small;
    //         color: $text-black-700;
    //         margin: 0; padding: 0;
    //     }
    // }
    .slotTimings {
        display: flex;
        justify-content: flex-end;

    }

    //     .fillTest{
    //         display: flex;
    //         flex-direction: column;
    //         align-items: flex-end;
    //         justify-content: center;
    //     .h6{
    //             font-size: $test-small;
    //             color: $text-black-700;
    //     }
    //     Button{
    //         margin: 10px 0 0 0;
    //         box-shadow: $shadow2;
    //     }
    // }
}


@media (max-width: 767px) {
    .AvailableSlotBg{
        background: none !important;
        padding: 0 !important;
    }
    .AvailableSlot{
        .col-6{
            width: 100% !important;
        }
        .row{
            flex-direction: column !important;
        }
        h2{
            font-size: 1.4rem;
        }
        .AvailableSlotCurrentDay{
            top: -215px;
            right: 10px;
        }
    }
  }