@import '../../assets/scss/variables.scss';
.UserAccount {
    background: $text-gray-900;
    padding: 40px 0 50px 0;
    h2{
        margin: 0;
        padding: 0 0 30px 0;
        color: $text-black-500;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
        span{
            color: $text-black-500;
            font-weight: $f-w-700;
            font-size: $test-small-light;
        }
    }
    .account{
        padding: 40px;
        margin: 0;
        background: $text-white;
        border-radius: $radius7;
        .passwordCnt{
            position: relative;
            button{
                position: absolute;
                top: 3px;
                right: 16px;                
                color: $primary;                
                font-size: $test-sm;
                font-weight: $f-w-700;
                border-radius: $radius3;
                padding: 0.150rem 0.75rem;
                background-color: $text-white;
                border:2px solid $text-red-200
            }
        }
        .texCode{
                position: relative;
            .text-muted{
                top:65px; left: 12px;
                color: $primary !important;                
                font-size: $test-sm;
                font-weight: $f-w-700;
                position: absolute;
            }
        }
        .btn-primary{
            margin: 30px 0 0 0;
            background-color: #BA0F31;
            &:hover{
                background-color: #BA0F31 !important;
            }
        }
       
    }
}