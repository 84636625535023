@import "../../assets/scss/variables.scss";
.UserRegister {
  .pull_up {
    margin-top: -200px !important;
  }
  .screen1 {
    display: flex;
    overflow: hidden;
    padding: 50px 0 0 0;
    flex-direction: column;
    justify-content: space-between;
    background: $text-black-500;
    // width: 700px;
    // margin: 0 auto;
    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 30px 0;
    }
    .innerScren1 {
      width: 980px;
      margin: 0 auto;
      padding: 40px;
      border-radius: $radius7;
      background: $text-white;
      h6 {
        font-size: $test-sm;
        margin: 0;
        padding: 0 0 10px 0;
        color: $text-gray-1001;
      }
      .row {
        margin-bottom: 10px;
      }
      ul {
        padding: 0 0 0 20px;
        li {
          border: 0;
          color: #080d2c;
          font-family: Raleway, sans-serif;
          font-size: 0.9rem !important;
          font-weight: 400;
          padding: 0.2rem 1rem;
          position: relative;
          &:after {
            content: "• ";
            position: absolute;
            left: 0;
          }
        }
      }
    }
    .bottom {
      height: 72px;
      width: 100%;
      margin: 50px 0 0 0;
      background: $text-gray-900;
      .innerBottom {
        width: 780px;
        margin: 0 auto;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 150px;
          font-size: $test-sm;
          letter-spacing: 1px;
          font-weight: $f-w-700;
          border-radius: $radius3;
          box-shadow: $shadow11;
          svg {
            left: 5px;
            position: relative;
          }
        }
        span {
          cursor: pointer;
        }
      }
    }
    .heading {
      padding: 0 0 50px 0;
      color: $text-black;
      font-weight: $f-w-700;
      font-size: $test-medium-large;
      font-family: "Inter", sans-serif;
    }
    .switch.btn {
      width: 200px;
      margin: 20px 0 15px 0;
    }
    ul {
      padding: 0 0 0 20px;
      li {
        border: 0;
        position: relative;
        padding: 0.2rem 1rem;
        font-family: "Raleway", sans-serif;
        font-weight: $f-w-400;
        font-size: $test-base;
        color: $text-black-500;
        &:after {
          content: "• ";
          position: absolute;
          left: 0;
        }
      }
    }
    h2 {
      padding: 0;
      margin: 0;
    }
    h2,
    h6 {
      font-family: "Raleway", sans-serif;
      font-weight: $f-w-600;
      color: $text-black-500;
      font-size: $test-base;
      color: var(--bs-heading-color) !important;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
      margin-top: 0;
      font-size: 1rem !important;
    }
    h6 {
      padding: 100px 0 10px 0;
    }
    Button {
      border-radius: $radius3;
      padding: 14px 30px;
      font-weight: $f-w-600;
      font-family: "Raleway", sans-serif;
      font-size: $test-medium-xlight;
    }
  }
  .screen2 {
    .innerScreen {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      .center-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2 {
          padding: 0 0 50px 0;
          margin: 0;
          font-family: "Inter", sans-serif;
          font-size: $test-medium-large;
          font-weight: $f-w-400;
        }
        Button {
          padding: 14px 30px;
          font-weight: $f-w-600;
          border-radius: $radius3;
          font-size: $test-medium-xlight;
          font-family: "Raleway", sans-serif;
        }
      }
    }
  }
  .screen3 {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .innerScreen {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 60%;
      .center-content {
        text-align: center;
        h2 {
          margin: 0;
          padding: 20px 0 20px 0;
          color: $text-black-1003;
          font-weight: $f-w-700;
          // font-family: 'Poppins', sans-serif;
          font-size: $test-medium-xsmall;
        }
        p {
          color: $text-gray-300;
          font-weight: $f-w-300;
          font-size: $test-small-light;
          // font-family: 'Work Sans', sans-serif;
        }
        h3 {
          padding: 50px 0 0 0;
          color: $text-black;
          font-weight: $f-w-400;
          font-family: "Inter", sans-serif;
          font-size: $test-medium-large;
        }
      }
      Button {
        padding: 10px 30px;
        font-weight: $f-w-600;
        border-radius: $radius3;
        font-size: 1rem;
        font-family: "Raleway", sans-serif;
      }
      .home-button {
        width: 100%;
      }
    }
  }
  .screen4 {
    display: none;
    height: 100vh;
    .innerScreen {
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      .center-content {
        text-align: center;
        h2 {
          font-weight: $f-w-600;
          font-family: "Work Sans", sans-serif;
          font-size: $text-3xl-small;
        }
        p {
          font-size: $test-small;
          font-weight: $f-w-300;
          padding: 0 10%;
          font-family: "Work Sans", sans-serif;
        }
        h3 {
          padding: 0 0 50px 0;
          margin: 0;
          color: $text-black;
          font-weight: $f-w-400;
          font-size: $text-lg;
        }
        svg {
          left: -170px;
          position: relative;
        }
      }
      Button {
        padding: 14px 30px;
        font-weight: $f-w-600;
        border-radius: $radius3;
        font-size: $test-medium-xlight;
        font-family: "Raleway", sans-serif;
      }
    }
  }
  .chq-atc {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .apple-wallet,
  .chq-atc--button {
    width: 100%;
    background: transparent;
    border: 1px solid #6a89af;
    border-radius: 5px;
    box-sizing: border-box;
    color: #6a89af;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    outline: none;
    padding: 4px 10px;
  }
  .chq-atc--button p,
  .apple-wallet p {
    margin-bottom: 0;
    width: 100%;
  }

  .chq-atc--button:focus {
    background-color: #eaeaea;
  }

  .chq-atc--button:hover {
    background-color: #eaeaea;
  }

  .chq-atc--button svg {
    vertical-align: text-bottom;
  }

  .chq-atc--button path {
    fill: #6a89af;
  }

  .chq-atc--dropdown {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    box-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    position: absolute;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    z-index: 1;
  }

  .chq-atc--dropdown a {
    color: #6a89af;
    display: block;
    padding: 8px 15px;
    text-decoration: none;
  }

  .chq-atc--dropdown a:hover {
    background-color: #eaeaea;
  }
}

@media (max-width: 768px) {
  .UserRegister {
    .pull_up {
      margin-top: 0 !important;
    }
    .innerScren1 {
      width: 90% !important;
    }
  }
}
