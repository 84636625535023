@import "../../assets/scss/variables.scss";
.Events {
  // background: $text-gray-900;
  padding: 50px 0 0 0;
  h2 {
    font-size: $test-medium-xsmall;
    font-weight: $f-w-700;
    color: $text-black-500;
    padding: 0 0 30px 0;
  }
  #Events::before {
    content: " ";
    display: block;
    visibility: hidden;
    pointer-events: none;
  }
}

@media (min-width: 320px) and (max-width: 960px) {
  #Events::before {
    margin-top: -400px;
    height: 400px;
  }
}
@media (min-width: 961px) {
  #Events::before {
    margin-top: -160px;
    height: 160px;
  }
}
