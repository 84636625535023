@import '../../../assets//scss//variables.scss';
.videoCard{
    .card{
        border: 0;
        border-radius: 0;
        .small-image{
            width: 100%;
            height: 70px;
        }
        h2{
            font-weight: $f-w-600;
            font-size: $test-base;
        }
        p{
            margin: 0;
            font-size: $test-sm;
        }
    }
}