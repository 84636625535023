@import '../../../assets/scss/variables.scss';
.cardSeven{
    margin-top: 20px;
    h5{
        margin: 0; padding: 0 0 18px 0;
        color: $text-black-500;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;     
    }
    .card{
        width: 500px;
        border: 0; padding: 40px 70px;
        box-shadow: $shadow9;
        border-radius: $radius7;
        .card-title{
            font-weight: $f-w-700;
            font-size: $test-small-light;
        }
        .card-text{
            padding: 12px 0;
            svg{
                margin-right: 5px;
            }
        }
        button{
            width: 100%;
        }
    }
}