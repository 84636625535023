@import "../../assets/scss/variables.scss";
.FAQ {
  padding: 50px 0;
  // border:0 !important;
  // box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.1);
  h2 {
    font-family: "Raleway", sans-serif;
    font-size: $text-3xl-light;
    font-weight: $f-w-800;
    color: $text-black-500;
    padding: 0 0 30px 0;
  }
  .card {
    cursor: pointer;
    margin: 0 0 30px 0;
    border: 0;
    box-shadow: $shadow3;
    .card-body {
      padding: 0;
      .card-header {
        border: 0;
        padding: 20px;
        color: $text-black-700;
        font-weight: $f-w-700;
        background: transparent;
        font-size: $test-small-light;
        svg {
          margin-right: 10px;
        }
      }
      .card-text {
        padding: 0 30px 50px 30px;
        color: $text-gray-1006;
      }
    }
    .open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .hide {
    display: none;
  }
  // .card{
  //     margin: 0 0 30px 0;
  //     border: 0;
  //     box-shadow: $shadow3;
  //     .card-header{
  //         background: $text-white;
  //         svg{
  //             margin-right: 20px;
  //         }
  //         h6{
  //             margin: 0;
  //             font-family: 'Work Sans', sans-serif;
  //             font-weight: $f-w-600;
  //             font-size: $test-small;
  //             color: $text-black-700;
  //         }
  //     }
  //     .card-body{
  //         padding: 16px 16px 16px 90px;
  //     }
  //     .open{
  //         svg{
  //             transform: rotate(180deg);
  //         }
  //     }
  // }
  #FAQ::before {
    content: " ";
    display: block;
    visibility: hidden;
    pointer-events: none;
  }
}

@media (min-width: 320px) and (max-width: 960px) {
  #FAQ::before {
    margin-top: -400px;
    height: 400px;
  }
}
@media (min-width: 961px) {
  #FAQ::before {
    margin-top: -160px;
    height: 160px;
  }
}
