@import "../../assets/scss/variables.scss";
.Quiz {
  padding: 0;
  position: relative;
  background: $adminBg;
  .progress {
    width: 100%;
    height: 11px;
    border-radius: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  .startquiz {
    // display: none;
    display: flex;
    justify-content: center;
    background: $text-gray-900;
    .content {
      width: 51%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      h2 {
        text-align: center;
        padding: 40px 0 0 0;
        font-weight: $f-w-600;
        font-size: $test-medium-xsmall;
        color: $text-gray-1005;
        font-family: "Work Sans", sans-serif;
        span {
          color: $primary;
          font-weight: $f-w-600;
          font-size: $test-medium-xsmall;
          font-family: "Work Sans", sans-serif;
        }
      }
      // h6{
      //     padding: 10px 0 0 0 ;
      //     text-align: center;
      //     font-weight: $f-w-300;
      //     font-size: $test-small;
      //     font-family: 'Work Sans', sans-serif;
      // }
      .card {
        border: 0;
        width: 500px;
        box-shadow: $shadow5;
        p {
          // font-size: $test-small;
          font-weight: $f-w-300;
          color: $text-black-700;
          strong {
            font-weight: $f-w-700 !important;
            font-weight: $f-w-300;
            color: $text-black-700;
          }
        }
        .form-check {
          display: inline-block;
        }
        .agree {
          padding: 10px 0 0 0;
          font-size: $test-sm;
          font-weight: $f-w-300;
          span {
            font-size: $test-sm;
            color: $text-red-600;
            font-weight: $f-w-700 !important;
            font-weight: $f-w-300;
          }
        }
      }
      .customBtn {
        font-size: $test-small;
        font-weight: $f-w-500;
        background: $primary;
        padding: 0.6rem 5rem;
        box-shadow: $shadow11;
        margin: 0 0 100px 0;
        border-radius: $radius3;
        border-color: $quaternary !important;
        font-family: "Work Sans", sans-serif;
      }
    }
  }
  .eligibilityQuiz {
    display: flex;
    overflow: hidden;
    padding: 50px 0 0 0;
    flex-direction: column;
    justify-content: space-between;
    background: $text-black-500;
    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 30px 0;
    }
    .innerScreen {
      width: 980px;
      margin: 0 auto 50px auto;
      padding: 40px;
      border-radius: $radius7;
      background: $text-white;
      ul {
        padding: 0 0 0 20px;
        li {
          border: 0;
          position: relative;
          padding: 0.2rem 1rem;
          font-family: "Raleway", sans-serif;
          font-weight: $f-w-400;
          font-size: $vvsmall;
          color: $text-black-500;
          &:after {
            content: "• ";
            position: absolute;
            left: 0;
          }
        }
      }
    }
    .bottom {
      display: flex;
      height: 72px;
      width: 100%;
      margin: 50px 0 0 0;
      align-items: center;
      background: $text-gray-900;
      .content {
        width: 780px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 150px;
          font-size: $test-sm;
          letter-spacing: 1px;
          font-weight: $f-w-700;
          border-radius: $radius3;
          box-shadow: $shadow11;
          svg {
            left: 5px;
            position: relative;
          }
        }
        span {
          cursor: pointer;
        }
      }
    }
  }
  .success {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    .innerContent {
      width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo {
        width: 100px;
      }
      h2 {
        margin: 0;
        padding: 15px 0;
        text-align: center;
        color: $text-black-1003;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
      }
      h6 {
        margin: 0;
        padding: 0 0 30px 0;
        text-align: center;
        color: $text-black-700;
      }
      button {
        border: 0;
        font-size: $test-sm;
        letter-spacing: 1px;
        font-weight: $f-w-700;
        border-radius: $radius3;
        padding: 0.6rem 1rem;
        background-color: $primary;
        &:hover,
        &:active,
        &:focus {
          border: 0;
          background-color: $primary !important;
        }
      }
    }
  }
  .failer {
    .innerContent {
    }
  }

  .screen1 {
    // display: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .content {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .loginCnt {
        h3 {
          color: $text-red-100;
          font-weight: $f-w-600;
          font-size: $text-3xl;
          padding: 0 0 50px 0;
          font-family: "Work Sans", sans-serif;
        }
        .form-cnt {
          display: flex;
          height: 80px;
          padding: 10px 20px;
          margin: 0 0 25px 0;
          align-items: center;
          background: $text-white;
          border-radius: $radius5;
          box-shadow: $shadow3;
          // $test-small
          .form-check-label {
            color: $text-black-1003;
            // font-size: $test-small;
            font-weight: $f-w-700;
          }
        }
      }
      .btnAlignment {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        .customBtn {
          font-size: $test-small;
          font-weight: $f-w-500;
          background: $primary;
          padding: 0.6rem 5rem;
          box-shadow: $shadow11;
          margin: 0 0 100px 0;
          border-radius: $radius3;
          border-color: $quaternary !important;
          font-family: "Work Sans", sans-serif;
        }
      }
    }
  }
  .screen2 {
    // display: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .content {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .loginCnt {
        h3 {
          color: $text-red-100;
          font-weight: $f-w-600;
          font-size: $text-3xl;
          padding: 0 0 50px 0;
          font-family: "Work Sans", sans-serif;
        }
        .form-cnt {
          display: flex;
          height: 80px;
          padding: 10px 20px;
          margin: 0 0 25px 0;
          align-items: center;
          background: $text-white;
          border-radius: $radius5;
          box-shadow: $shadow3;
          // $test-small
          .form-check-label {
            color: $text-black-1003;
            // font-size: $test-small;
            font-weight: $f-w-700;
          }
        }
      }
      .btnAlignment {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .customBtn {
          font-size: $test-small;
          font-weight: $f-w-500;
          background: $primary;
          padding: 0.6rem 5rem;
          box-shadow: $shadow11;
          margin: 0 0 100px 0;
          border-radius: $radius3;
          border-color: $quaternary !important;
          font-family: "Work Sans", sans-serif;
        }
        .backBtn {
          border: 0;
          padding: 0;
          margin: 5px 0 0 0;
          color: $text-black;
          font-size: $test-sm;
          font-weight: $f-w-700;
          background: transparent;
          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .screen3 {
    // display: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .content {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .loginCnt {
        h3 {
          color: $text-red-100;
          font-weight: $f-w-600;
          font-size: $text-3xl;
          padding: 0 0 50px 0;
          font-family: "Work Sans", sans-serif;
        }
        .form-cnt {
          display: flex;
          height: 80px;
          padding: 10px 20px;
          margin: 0 0 25px 0;
          align-items: center;
          background: $text-white;
          border-radius: $radius5;
          box-shadow: $shadow3;
          // $test-small
          .form-check-label {
            color: $text-black-1003;
            // font-size: $test-small;
            font-weight: $f-w-700;
          }
        }
      }
      .btnAlignment {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .customBtn {
          font-size: $test-small;
          font-weight: $f-w-500;
          background: $primary;
          padding: 0.6rem 5rem;
          box-shadow: $shadow11;
          margin: 0 0 100px 0;
          border-radius: $radius3;
          border-color: $quaternary !important;
          font-family: "Work Sans", sans-serif;
        }
        .backBtn {
          border: 0;
          padding: 0;
          margin: 5px 0 0 0;
          color: $text-black;
          font-size: $test-sm;
          font-weight: $f-w-700;
          background: transparent;
          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .screen4 {
    // display: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .loginCnt {
        h3 {
          color: $text-red-100;
          font-weight: $f-w-600;
          font-size: $text-3xl;
          padding: 0 0 50px 0;
          font-family: "Work Sans", sans-serif;
        }
        .form-cnt {
          display: flex;
          height: 80px;
          padding: 10px 20px;
          margin: 0 0 25px 0;
          align-items: center;
          background: $text-white;
          border-radius: $radius5;
          box-shadow: $shadow3;
          // $test-small
          .form-check-label {
            color: $text-black-1003;
            // font-size: $test-small;
            font-weight: $f-w-700;
          }
        }
      }
      .btnAlignment {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .customBtn {
          font-size: $test-small;
          font-weight: $f-w-500;
          background: $primary;
          padding: 0.6rem 5rem;
          box-shadow: $shadow11;
          margin: 0 0 100px 0;
          border-radius: $radius3;
          border-color: $quaternary !important;
          font-family: "Work Sans", sans-serif;
        }
        .backBtn {
          border: 0;
          padding: 0;
          margin: 5px 0 0 0;
          color: $text-black;
          font-size: $test-sm;
          font-weight: $f-w-700;
          background: transparent;
          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .screen5 {
    // display: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .loginCnt {
        h3 {
          color: $text-red-100;
          font-weight: $f-w-600;
          font-size: $text-3xl;
          padding: 0 0 50px 0;
          font-family: "Work Sans", sans-serif;
        }
        .form-cnt {
          display: flex;
          height: 80px;
          padding: 10px 20px;
          margin: 0 0 25px 0;
          align-items: center;
          background: $text-white;
          border-radius: $radius5;
          box-shadow: $shadow3;
          // $test-small
          .form-check-label {
            color: $text-black-1003;
            // font-size: $test-small;
            font-weight: $f-w-700;
          }
        }
      }
      .btnAlignment {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .customBtn {
          font-size: $test-small;
          font-weight: $f-w-500;
          background: $primary;
          padding: 0.6rem 5rem;
          box-shadow: $shadow11;
          margin: 0 0 100px 0;
          border-radius: $radius3;
          border-color: $quaternary !important;
          font-family: "Work Sans", sans-serif;
        }
        .backBtn {
          border: 0;
          padding: 0;
          margin: 5px 0 0 0;
          color: $text-black;
          font-size: $test-sm;
          font-weight: $f-w-700;
          background: transparent;
          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .screen6 {
    // display: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .loginCnt {
        h3 {
          color: $text-red-100;
          font-weight: $f-w-600;
          font-size: $text-3xl;
          padding: 0 0 50px 0;
          font-family: "Work Sans", sans-serif;
        }
        .form-cnt {
          display: flex;
          height: 80px;
          padding: 10px 20px;
          margin: 0 0 25px 0;
          align-items: center;
          background: $text-white;
          border-radius: $radius5;
          box-shadow: $shadow3;
          // $test-small
          .form-check-label {
            color: $text-black-1003;
            // font-size: $test-small;
            font-weight: $f-w-700;
          }
        }
      }
      .btnAlignment {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .customBtn {
          font-size: $test-small;
          font-weight: $f-w-500;
          background: $primary;
          padding: 0.6rem 5rem;
          box-shadow: $shadow11;
          margin: 0 0 100px 0;
          border-radius: $radius3;
          border-color: $quaternary !important;
          font-family: "Work Sans", sans-serif;
        }
        .backBtn {
          border: 0;
          padding: 0;
          margin: 5px 0 0 0;
          color: $text-black;
          font-size: $test-sm;
          font-weight: $f-w-700;
          background: transparent;
          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .endQuiz {
    // display: none;
    display: flex;
    justify-content: center;
    .content {
      width: 51%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      h2 {
        text-align: center;
        padding: 100px 0 0 0;
        font-weight: $f-w-700;
        color: $text-black-1000;
        font-size: $test-medium-xsmall;
        span {
          color: $primary;
          font-weight: $f-w-600;
          font-size: $text-3xl;
          font-family: "Work Sans", sans-serif;
        }
      }
      h6 {
        padding: 10px 0 0 0;
        text-align: center;
        font-weight: $f-w-300;
        font-size: $test-small;
        font-family: "Work Sans", sans-serif;
      }
      .card {
        border: 0;
        width: 500px;
        box-shadow: $shadow5;
        p {
          // font-size: $test-small;
          font-weight: $f-w-400;
          color: $text-black-700;
        }
        .form-check {
          display: inline-block;
        }
        .agree {
          padding: 10px 0 0 0;
          font-weight: $f-w-300;
          font-family: "Work Sans", sans-serif;
          span {
            color: $quaternary;
            font-weight: $f-w-300;
            font-family: "Work Sans", sans-serif;
          }
        }
      }
      .customBtn {
        width: 500px;
        font-size: $test-small;
        font-weight: $f-w-500;
        background: $primary;
        padding: 0.6rem 5rem;
        box-shadow: $shadow11;
        margin: 0 0 100px 0;
        border-radius: $radius3;
        border-color: $quaternary !important;
        font-family: "Work Sans", sans-serif;
      }
    }
  }
  .quiz-list {
    li {
      font-size: 0.9rem !important;
    }
  }
}
