@import '../../assets/scss/variables.scss';
.AdminLayout {
    display: flex;
    flex-direction: row;
    .innerContent{
        height: 100vh;
        width: calc(100% - 233px);        
        background: $text-white;
        overflow: auto;
        .customContainer{
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}