
@import '../../../assets/scss/variables.scss';
.customCardTwo{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    div {
        span {
            p{
                margin-left: 10px;
                margin-top: 15px;
            }
        }
    }
    .card{ 
        border: 0;
        margin: 0;
        padding: 0;
        height: 100%;
        border-radius: $radius5;
        background: $text-gray-900;
        .p-2{
            padding: 0 !important;
        }
        img{
            height: 121px;
            object-fit:cover !important;
            border-top-left-radius: $radius5;
            border-top-right-radius: $radius5;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .card-body{
            text-align: left;
            display: flex; justify-content: space-between;
            flex-direction: column; padding: 30px; margin: 0; 

            .card-title{              
                margin: 0; padding: 0 0 22px 0;          
                font-size: $text-lg;
                font-weight: $f-w-700;
                color: $text-blackgray-100;
            }
            .card-text{              
                display: -webkit-box;
                -webkit-line-clamp: 3; 
                -webkit-box-orient: vertical;
                overflow: hidden;                
            }
            .card-text,span{
                color: $text-blackgray-100;
            }
            span{                
                display: flex;
                padding: 5px 0 0 0;
                align-items: center;
            }
            .card-footer{
                padding: 0;
                border: 0; margin: 0;
                background: transparent;
                button{                    
                    width: 100%;                    
                    color: $primary;
                    margin:  50px 0 0 0;
                    font-size: $test-sm;
                    letter-spacing: 1px;
                    font-weight: $f-w-700;
                    border-radius: $radius3;
                    padding: 0.5rem 0.75rem;
                    background: transparent;                       
                    border: 2px solid $text-red-200;    
                    &:hover,&:focus{
                        background: transparent;
                        color: $primary;
                    }
                    svg{
                        width: 18px;
                        height: 14px;
                    }
                }
            }
        }
    }    
}