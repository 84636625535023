.NoticeCard {
    .noticeSaveBtn {
        position: absolute;
        bottom: 40px;
        right: 40px;
    }

    .dayDifferene {
        .col-lg-8 {
            width: calc(100% - 165px) !important;
        }

        .col-lg-4 {
            width: 165px !important;
        }
    }

    .form-switch {
        gap: 50px;
        display: flex;
        align-items: center;
        padding: 0;
        margin-top: 0;
        --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e') !important;
        input {
            &:focus{
                --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');

            }
            &:checked {
                background-position: right center;
                --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%2834, 100, 209, 1%29%27/%3e%3c/svg%3e');
            }
        }
    }

    #remainderDay {
        border: 0;
        outline: 0;
        background-color: #E1E1E3;
        width: 40px;
        height: 24px;
        box-shadow: none;
        background-size: 72%;
        border-radius: 99px;
        order: 2;
    }

    .variables {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    .variable {
        background-color: #fff;
        border: 1px solid #B4B4BB;
        border-radius: 99px;
        padding: 8px 20px;
        position: relative;
    }

    .delete_username {
        cursor: pointer;
        height: 8px;
        width: 8px;
        margin: 0 6px;
        margin-left: 15px;
    }

    .col-reverse {
        flex-direction: row;
        display: flex;
        align-items: center;
        margin-top: 20px;
        gap: 20px;
    }

    .var-modal {
        ._modal__body {
            text-align: left;
        }

        .redButton {
            background-color: #2979FF !important;
        }

        .redButtonShallow {
            border-color: #2979FF !important;
            color: #2979FF !important;
            border-width: 2px;
        }
    }
}