@import "../../assets/scss/variables.scss";
.SetPwd {
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 30px 0 0 0;
  // align-items: center;
  justify-content: center;
  background: $adminBg url(../../assets/images/heartbg.png) no-repeat left bottom;
  background-size: 100%;
  .innerLogin {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px 0;
    justify-content: space-between;
    .logo {
      margin: 0;
      display: inline-block;
    }
    // h3{
    //     margin: 0;
    //     text-align: center;
    //     padding: 30px 0 25px 0;
    //     font-weight: $f-w-700;
    //     font-size: $test-medium-xsmall;
    //     color: $text-blackgray-100;
    //     strong{
    //         font-weight: $f-w-700;
    //         font-size: $test-medium-xsmall;
    //         color: $text-blackgray-100;
    //     }
    // }

    .forgetCard {
      width: 420px;
      padding: 40px;
      background: $text-white;
      border-radius: $radius7;
      box-shadow: $shadow9;
      h4 {
        font-weight: $f-w-700;
        color: $text-blackgray-100;
        font-size: $test-small-light;
        text-align: center;
      }
      form {
        width: 100%;
        .closeEmail {
          right: 11px;
          bottom: 12px;
          cursor: pointer;
          position: absolute;
        }
        button {
          width: 100%;
          background: $primary;
          margin: 0;
          padding: 0.57rem 0.75rem;
        }
        input {
          border-radius: $radius3;
          padding: 0.475rem 0.75rem;
          border: 1px solid $border6;
          font-family: "Raleway", sans-serif;
          &:focus {
            border: 2px solid $text-blackgray-300;
          }
        }
        label {
          font-size: $test-sm;
        }
        .btn.btn-primary {
          font-size: $test-sm;
          letter-spacing: 1px;
          margin: 24px 0 0 0;
          font-weight: $f-w-700;
          border-radius: $radius3;
        }
        .btn-outline-primary {
          background: transparent;
        }
      }
      p {
        // text-align: center;
        font-size: $text-small;
        color: $text-gray-300;
        padding: 0;
        margin: 0;
        font-family: "Raleway", sans-serif;
        span {
          font-size: $test-sm;
          color: $text-blue-100;
          font-weight: $f-w-700;
          font-family: "Raleway", sans-serif;
        }
      }
      .form-check {
        input {
          margin-right: 10px;
        }
        label {
          font-size: $test-sm;
          color: $text-gray-1005;
          font-family: "Raleway", sans-serif;
        }
      }
      a {
        color: $text-blue-100;
        font-size: $text-small;
        font-family: "Raleway", sans-serif;
      }
      .customBtn {
        width: 47%;
        font-size: $test-sm;
        font-weight: $f-w-700;
        background: transparent;
        color: $text-gray-1004;
        border: 2px solid $border6;
        padding: 0.575rem 0.75rem;
        font-family: "Raleway", sans-serif;
        svg {
          float: left;
        }
      }
      .oulineBnt {
        width: 100%;
        font-family: "Raleway", sans-serif;
        padding: 0.575rem 0.75rem;
        font-weight: $f-w-600;
        &:hover {
          color: $primary;
          background-color: $text-white;
        }
      }
      .orDiv {
        font-size: $test-sm;
        color: $text-gray-300;
        position: relative;
        height: 20px;
        width: 100%;
        text-align: center;
        margin: 0 0 30px 0;
        span {
          top: -3px;
          z-index: 5;
          padding: 0 7px;
          position: relative;
          font-size: $text-small;
          color: $text-gray-1001;
          background: $text-white;
        }
        &:before {
          content: "";
          background: $text-gray-1002;
          height: 1px;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .SetPwd {
    .innerLogin {
      width: 100% !important;
      .forgetCard {
        width: 100% !important;
      }
    }
  }
}
