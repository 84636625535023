@import '../../assets//scss/variables.scss';
.AddDonationCenter {
    h2{
        margin: 0; padding: 0 0 20px 0;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
    }
    button{
        position: absolute;
        bottom: 40px; right: 40px;
    }
    .analysisGroup{
        display: flex;
        gap: 20px;
        align-items: center;
    }
    .analysis{
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: #787885;
        p{
            margin-bottom: 0;
        }
    }
    .form-check.radio {
        padding-top: 7px;
        padding-left: 30px;
        padding-bottom: 10px;
    }

    .form-check.selected {
        border-radius: 12px;
        background-color: transparent;

    }

    .form-check-input {
        border-color: #000;
        position: relative;

    }
    svg{
        margin-left: 5px;
    }
    .form-check-input:checked {
        background-color: transparent;
        border-color: #000;
        border: 2px solid #000;
    }

    .form-check-input:focus {
        border-color: #000;
        outline: 0;
        box-shadow: none;
    }

    .form-check-input:checked[type=radio] {
        --bs-form-check-bg-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23000%27/%3e%3c/svg%3e');
    }
}