@import '../../assets/scss/variables.scss';
.PageNotFound{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;  
    background: $adminBg url(../../assets/images/heartbg.png) no-repeat left bottom;
    background-size: 100%;
    flex-direction: column;
    img{width: 200px;}
    h2{
        padding: 0;
        margin: 30px 0 20px 0;
        font-weight: $f-w-700;
        color: $text-blackgray-100;
        font-size: $test-medium-xsmall;
    }
    .content{
        width: 400px;        
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $text-white;                
        padding: 40px;
        border-radius: $radius7;
        box-shadow: $shadow3;
        h5{
            margin: 0;
            text-align: center;
            position: relative;
            // padding: 30px 0 25px 0;
            font-weight: $f-w-700;            
            font-family: 'Raleway', sans-serif;
            font-size: $test-small-light;
            color: $text-blackgray-100; 
        }
        .UseButton{
            width: 100%;
            button{
                width: 100%;
                margin: 20px 0 0 0;
                letter-spacing: 1px;
                font-size: $test-sm;
                font-weight: $f-w-700;                
                padding: 0.475rem 0.75rem;
                border-radius: $radius3;
            }
        }        
    }
}