@import '../../../assets/scss/variables.scss';
.customCardOne{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
.card{    
    margin-bottom: 30px;
    padding: 30px;
    border:0 !important;
    width: 100%; 
    border-radius: $radius1 !important;
    background: $text-gray-900;   
        .card-body{
            padding: 0;
            display: flex;
            text-align: left;
            flex-direction: column;
            justify-content: space-between;
            .card-header{           
                border: 0;                      
                margin: 0;
                display: flex;
                align-items: center;
                padding: 0 0 20px 0;
                flex-direction: row;                                
                background: transparent;                                
                font-size: $test-small;
                color: $text-blackgray-100;
                svg{
                    margin-right: 10px;
                }
            }
            .card-title {        
                margin: 0 0 30px 0;                     
                font-size: $text-lg;
                font-weight: $f-w-700;
                color: $text-blackgray-100;
            }
            .card-text{ 
                margin: 0;
                // padding: 30px 0;
                color: $text-blackgray-100;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .card-footer{
                padding: 0;
                margin: 0;                
                border: 0;
                background: transparent;
                button{                    
                    width: 100%;                    
                    color: $primary;
                    font-size: $test-sm;
                    letter-spacing: 1px;
                    font-weight: $f-w-700;
                    border-radius: $radius3;
                    margin: 30px 0 0 0;
                    padding: 0.5rem 0.75rem;
                    background: transparent;                       
                    border: 2px solid $text-red-200;    
                    &:hover,&:focus{
                        background: $text-white;
                        color: $primary;
                    }
                    svg{
                        width: 18px;
                        height: 14px;
                    }
                }
            }
        }      
}
}