@import '../../../assets/scss/variables.scss';

.CardFour {
    margin: 0 0 30px 0;
    .card {
        border: 0;
        outline: 0;
        background: $text-white;
        box-shadow: $shadow10;

        .card-body {
            .card-title {
                display: flex;
                padding: 8px 12px;
                color: $text-gray-1001;
                font-weight: $f-w-700;
                border-radius: $radius9;
                border: 1px solid $border6;
                background: $text-gray-1003;
                justify-content: space-between;
                font-family: 'Raleway', sans-serif;
            }

            .content {
                display: flex;
                padding: 0;
                flex-direction: column;

                .list-group {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    gap: 8px;
                    .list-group-item {
                        width: 100%;
                        border: 0;
                        padding: 16px 13px 8px 12px;
                        border-radius: $radius9;
                        justify-content: space-between;
                        display: flex;
                        flex-direction: column;
                        background-color: $text-gray-900;
                        .delete_username{
                            cursor: pointer;
                            height: 8px;
                            width: 8px;
                            margin: 0 6px;
                        }
                        button {
                            padding: 2px 8px;
                            margin: 8px 0 0 0;
                            background: $green;
                            color: $text-gray-900;
                            font-size: $text-small;
                            border-radius: $radius10;
                            border: 1px solid $border7;
                            font-family: 'Raleway', sans-serif;
                            width: fit-content;
                        }

                        .close {
                            right: 0;
                            position: absolute;
                            cursor: pointer;
                        }

                        h6 {
                            padding: 0;
                            display: flex;
                            font-size: $test-sm;
                            font-weight: $f-w-700;
                            color: $text-gray-1001;
                            position: relative;
                        }

                        p.slot {
                            color: $text-gray-1001;
                            font-family: 'Raleway', sans-serif;
                        }

                        p.username {
                            padding: 4px 6px;
                            border-radius: 99px;
                            display: inline-block;
                            margin: 0 13px 13px 0;
                            border: 1px solid $border6;
                            background: $text-white;
                            font-family: 'Raleway', sans-serif;
                        }
                        .users_wrap{
                            display: flex;
                            flex-wrap: wrap;
                            gap: 13px;
                        }
                        .username{
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                span {
                    padding: 4px 6px;
                    border-radius: 99px;
                    outline: 1px solid $border6;
                    background: $text-white;
                    font-size: 12px;
                    color: $text-gray-1004;
                }
            }

            .card-footer {
                border: 0;
                padding: .8rem;
                margin-top: .8rem;
                background: $text-gray-900;

                .list-group {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;

                    .list-group-item {
                        border: 0;
                        padding: 0;
                        margin: 0 15px 15px 0;
                        display: flex;
                        font-size: $test-sm;
                        font-weight: $f-w-700;
                        color: $text-gray-1001;
                        background: transparent;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}