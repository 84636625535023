@import '../../assets/scss/variables.scss';
.useImageUploadOne{
    color: $active;    
    padding: 7px 15px;
    display: inline-flex;
    background-color: $text-white;
    border:2px solid $border5;
    font-weight: $f-w-700;
    border-radius: $radius3;    
    &:hover,&:active{
        outline: 0;
        color: $active;
        background-color: $text-white !important;
        border:2px solid $border5 !important;
    }
    input{
        position: fixed;
        left: 1000000px;
    }
}