@import "../../assets/scss/variables.scss";

.DonationTimeline {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
  position: relative;
  min-width: 1140px;
  .DonationTimelineItemText {
    position: relative;
    span {
      position: absolute;
      top: -12px;
      right: 0;
      font-size: 12px;
      opacity: .8;
    }
  }

  .DonationTImelineBar {
    height: 4px;
    border-bottom-left-radius: 6px;
    position: absolute;
    max-width: 100%;
    background-color: #BA0F31;
    /* bottom: 41px; */
    left: 0;
    top: 44px;
    z-index: 99;
  }

  .DonationTimelineItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 21%;
    font-weight: 700;
    position: relative;

    .DonationTimelineItemIcon {
      margin-top: 10px;
    }

    .DonationTimelineItemTongue {
      margin-bottom: -5px
    }
  }

  .DonationTimelineItemLine {
    height: 5px;
    background-color: #D9D9D9;
    width: 100%;
  }

  .DonationTimelineItemFirst {
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    flex-basis: 8.33%;

    &::before {
      position: absolute;
      content: '';
      height: 22px;
      width: 8px;
      background-color: #D9D9D9;
      border-bottom-left-radius: 40px;
      border-top-right-radius: 40px;
      top: 26px;
      left: 0px;
    }

    .DonationTimelineItemTongue {
      svg {
        visibility: hidden;
        ;
      }
    }

    .DonationTimelineItemLine {
      border-bottom-left-radius: 40px;
    }
  }

  .DonationTimelineItemLast {
    justify-content: flex-end;
    align-items: flex-end;
    flex-basis: 8.33%;

    .DonationTimelineItemLine {
      border-bottom-right-radius: 40px;
    }

    .DonationTimelineItemTongue {
      svg {
        visibility: hidden;
        ;
      }
    }

    .DonationTimelineItemTongue {
      svg {
        visibility: hidden;
        ;
      }
    }

    position: relative;

    &::after {
      position: absolute;
      content: '';
      height: 22px;
      width: 8px;
      background-color: #D9D9D9;
      border-bottom-right-radius: 40px;
      border-top-left-radius: 40px;
      top: 26px;
      right: 0px;
    }
  }

}

@media (max-width: 767px) {
  .DonationTimelineWrap{
    overflow: hidden;
    .DonationTimelineWrapInner{
      overflow: auto;
      overflow-y: visible;
    }
  }
}