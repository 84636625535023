@import '../../assets/scss/variables.scss';
.bookingStatus{
    position: relative;
  
    .showBookingStatus{
        display: flex;
        cursor: pointer;        
        color: $text-white;
        font-weight: $f-w-700;
        width: 327px; height: 40px;
        align-items: center;        
        border-radius: $radius3;
        justify-content: center;
        font-size: $test-base;        
        font-family: 'Raleway', sans-serif;                       
    }
    .progress{
        background: $text-gray-1007 url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") no-repeat 95% center/12px;        
    }
    .carried{
        background: #37833B url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") no-repeat 95% center/12px;        
    }
    .cancelled{
        background: #19191D url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") no-repeat 95% center/12px;        
    }
    .failed{
        background: #BA0F31 url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") no-repeat 95% center/12px;        
    }
    .analyzed{
        background: #CE6B00 url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") no-repeat 95% center/12px;        
    }
    .selctBookingSttaus{
        width: 327px;
        padding: 10px;
        z-index: 999;
        position: absolute;
        left: 0; bottom: -216px;        
        background: $text-white;
        border-radius: 12px;
        border: 1px solid $border6;
        
        input[type='radio']{
            width: 20px; height: 20px;
            box-shadow: none;
            border: 2px solid $text-gray-1004;
            cursor: pointer;
        }
        .status{
            display: flex;
            height: 48px;
            padding: 10px;
            align-items: center;
            border-radius: $radius13;
            label{
                top: 3px;
                margin-left: 10px;
                cursor: pointer;
                position: relative;
            }
        }
        .active{
            display: flex;
            height: 48px;
            padding: 10px;
            align-items: center;
            border-radius: $radius13;
            background: $text-gray-1003;
            label{
                top:3px;
                font-weight: $f-w-700;
                margin-left: 10px;
                cursor: pointer;
                position: relative;
            }
        }
        .form-check-input:checked[type=radio] {
            --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='rgba(74, 75, 87, 1)'/%3e%3c/svg%3e");
          }
          .form-check-input:checked{background-color: transparent;}
    }    
}
.bookingStatusDisabled{
    pointer-events: none;
    .showBookingStatus{
        background: #EDEDF0 !important;
    }
}