@import '../../assets/scss/variables.scss';
.AlertManagement {
    h2{        
        margin: 0;
        padding: 0 0 30px 0 ;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
        font-family: 'Raleway', sans-serif;
    }   
    p{        
        font-size: $test-sm;        
        font-weight: $f-w-700;
        color: $text-gray-1001;
        font-family: 'Raleway', sans-serif;
    }
    a{

    }
}