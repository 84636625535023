@import '../../assets/scss/variables.scss';
.AddReservations {
    h2{
        margin: 0;
        padding: 0 0 30px 0;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
    }
    .selctedTimeList{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span{
            outline: 0;
            position: relative;
            margin: 0 10px 0 0;            
            color: $text-gray-1004;                  
            border-radius: $radius3;             
            padding: 3px 20px 3px 10px;                              
            background-color: $text-white;
            border:1px solid $text-gray-1004;
            svg{
                cursor: pointer;
                position: absolute; right: 8px;
                top:50%; transform: translateY(-50%);
            }
        }
    }
    .connectCompanies{
        label{
            width: 100%;          
        }      
        .connectGroup{
            display: flex;
            flex-direction: row;
            align-items: center;
            .addInterval{
                position: relative;
                margin-right: 15px;
            }
            span{
                height: 32px;
                display: flex;                
                padding: 4px 10px;
                margin-right: 8px;                
                position: relative;         
                border-radius: 99px;
                align-items: center;
                color: $text-gray-1004;
                padding: 0 35px 0 10px;
                background: $text-white;
                border: 1px solid $border6;                     
                                  
                b{  
                    top: 50%; right: 15px;                                         
                    cursor: pointer;      
                    position: absolute;                 
                    transform: translateY(-50%);                    
                }
            }
        }
    }
    .availbleSlots{
        display: flex;
        flex-direction: row;
        ul{
            margin: 0; padding:  0 0 30px 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            li{
                margin: 0 15px 10px 0;
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }
}