@import '../../assets/scss/variables.scss';
.AddCenter {
    h2{
        margin: 0;
        padding: 0 0 30px 0;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
        font-family: 'Raleway', sans-serif;
    }
    .addCenter{
        position: relative;
            .nav{
                border: 0;
                margin-bottom: 30px !important;
                li{
                    button{
                        border-top: 0;
                        border-left: 0;
                        border-right: 0;
                        font-weight: $f-w-700;
                        color: $text-black-500;
                        background: transparent;
                        border-bottom: 2px solid transparent;
                        font-family: 'Raleway', sans-serif;
                    }
                    .nav-link.active{
                        color: $active;
                        border-bottom: 4px solid $active;
                        font-family: 'Raleway', sans-serif;
                    }
                }
        }
        .groupBtn{
            display: flex;
            flex-direction: row;
            position: absolute;
            top: -51px;
            right: 0;
            .UseButton:first-child{
                button{
                    margin-right: 18px;
                }
            } 
        }
    }
    
}