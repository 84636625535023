@import "../../assets/scss/variables.scss";
.Forgot {
  display: flex;
  width: 100%;
  height: 100vh;
  // align-items: center;
  padding: 30px 0 0 0;
  justify-content: center;
  background: $adminBg url(../../assets/images/heartbg.png) no-repeat left bottom;
  background-size: 100%;
  .innerLogin {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px 0;
    .logo {
      margin: 0;
      display: inline-block;
    }
    h3 {
      margin: 0;
      text-align: center;
      padding: 30px 0 50px 0;
      font-weight: $f-w-700;
      font-size: $test-medium-xsmall;
      color: $text-blackgray-100;
      strong {
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
        color: $text-blackgray-100;
      }
    }

    .forgetCard {
      width: 420px;
      padding: 40px;
      background: $text-white;
      border-radius: $radius7;
      box-shadow: $shadow9;
      h4 {
        margin: 0;
        text-align: center;
        font-weight: $f-w-700;
        padding: 0 10px 20px 10px;
        color: $text-blackgray-100;
        font-size: $test-small-light;
      }
      form {
        width: 100%;
        button {
          margin: 0;
          width: 100%;
          font-size: $test-sm;
          background: $primary;
          font-weight: $f-w-700;
          border-radius: $radius3;
          padding: 0.57rem 0.75rem;
        }
        input {
          font-family: "Raleway", sans-serif;
          border-radius: $radius3;
          padding: 0.47rem 0.75rem;
          border: 1px solid $border6;
          &:focus {
            border: 2px solid $text-blackgray-300;
          }
        }
        .btn.btn-primary {
          margin: 19px 0;
          letter-spacing: 1px;
        }
        .btn-outline-primary {
          letter-spacing: 1px;
          font-weight: $f-w-700;
          background: transparent;
          border-radius: $radius3;
        }
        label {
          font-size: $test-sm;
        }
      }
      p {
        text-align: left;
        font-size: $text-small;
        color: $text-gray-300;
        padding: 0;
        margin: 0;
        font-family: "Raleway", sans-serif;
        span {
          font-size: $test-sm;
          color: $text-blue-100;
          font-weight: $f-w-700;
          font-family: "Raleway", sans-serif;
        }
      }
      .form-check {
        input {
          margin-right: 10px;
        }
        label {
          font-size: $test-sm;
          color: $text-gray-1005;
          font-family: "Raleway", sans-serif;
        }
      }
      a {
        color: $text-blue-100;
        font-size: $text-small;
        font-family: "Raleway", sans-serif;
      }
      .closeEmail {
        right: 11px;
        bottom: 12px;
        cursor: pointer;
        position: absolute;
      }
      .customBtn {
        width: 47%;
        font-size: $test-sm;
        font-weight: $f-w-700;
        background: transparent;
        color: $text-gray-1004;
        border: 2px solid $border6;
        padding: 0.575rem 0.75rem;
        font-family: "Raleway", sans-serif;
        svg {
          float: left;
        }
      }
      .oulineBnt {
        width: 100%;
        font-family: "Raleway", sans-serif;
        padding: 0.5rem 0.75rem;
        font-weight: $f-w-600;
        border: 2px solid $text-red-200;
        &:hover {
          color: $primary;
          background-color: $text-white;
        }
      }
      .orDiv {
        font-size: $test-sm;
        color: $text-gray-300;
        position: relative;
        height: 20px;
        width: 100%;
        text-align: center;
        margin: 0 0 30px 0;
        span {
          top: -3px;
          z-index: 5;
          padding: 0 7px;
          position: relative;
          font-size: $text-small;
          color: $text-gray-1001;
          background: $text-white;
        }
        &:before {
          content: "";
          background: $text-gray-1002;
          height: 1px;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .Forgot {
    .innerLogin {
      width: 100% !important;
      h3 {
        font-size: 1.8rem;
      }
      .forgetCard {
        width: 100% !important;
      }
    }
  }
}
