@import "../../assets/scss/variables.scss";
.Dashboard {
  h2 {
    margin: 0;
    padding: 45px 0 30px 0;
    font-weight: $f-w-700;
    font-size: $text-3xl-light;
    font-family: "Raleway", sans-serif;
    span {
      display: inline-block;
      font-weight: $f-w-700;
      font-size: $text-3xl-light;
      font-family: "Raleway", sans-serif;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .activeUser {
    background: $text-teal-100;
    width: 13px;
    height: 13px;
    border-radius: 50px;
    display: inline-block;
  }
  .inactiveUser {
    background: $pink;
    width: 13px;
    height: 13px;
    border-radius: 50px;
    display: inline-block;
  }
  h6 {
    display: flex;
    color: $text-black;
    font-size: $text-lg;
    font-weight: $f-w-700;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    font-family: "Raleway", sans-serif;
    .nav-link {
      height: 40px;
      margin: 0;
      padding: 10px 20px 0 20px;
      color: $active;
      font-size: $test-sm;
      border: 2px solid $border5;
      border-radius: $radius3;
      font-weight: $f-w-700;
    }
  }

  .userStatus {
    width: 13px;
    height: 13px;
    border-radius: 50px;
    display: inline-block;
  }
  .CardBox {
    padding: 0 0 50px 0;
  }

  .CardList {
    padding: 0 0 50px 0;
  }
  .table {
    margin: 20px 0 0 0;
    span {
      color: $text-indigo-70;
      cursor: pointer;
    }
    th {
      border-bottom: 2px solid $text-gray-1001;
      color: $text-gray-1001;
    }
    td {
      color: $text-black-1003;
      background-color: $text-white;
    }
    .user_name,
    .user_id {
      color: $text-indigo-70;
    }
  }
  .tableCol {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 650px) {
  .Dashboard {
    .tableCol {
      h6 {
        font-size: 1.2rem !important;
      }
    }
  }
}
