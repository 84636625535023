@import '../../../assets/scss/variables.scss';
.CardFive{
    .card{
        border: 0;        
        padding: 0;
        margin: 0 0 18px 0;
        box-shadow: $shadow9;
        .card-body{
            .card-text{                
                display: flex;
                font-size: $test-sm;
                color: $text-gray-1001;
                font-weight: $f-w-700;
                justify-content: space-between;
                font-family: 'Raleway', sans-serif;
                a{


                }
            }
        }
    }
}   