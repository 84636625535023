@import '../../assets/scss/variables.scss';
.useCounter{
    display: flex;
    flex-direction: row;
    border-radius: $radius14;
    border:1px solid $border8;
    background-color: $text-white;
    button:disabled{
        background-color: #e9ecef !important;
    }
    button{
        width: 100%;
        border-radius: 0; border: 0;
        background-color: transparent;
        &:hover,&:focus,&:active,&.active{
            background-color: transparent !important;
            border: 0; outline: 0;
        }
    }
    input{
        width: 55px;
        padding: 0;
        outline: 0;
        border-top: 0;
        border-bottom: 0;
        border-radius: 0;
        text-align: center;
        color: $text-black;
        font-weight: $f-w-700;
        border-left: 1px solid $border8;
        border-right: 1px solid $border8;
    }
}