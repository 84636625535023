@import '../../assets/scss/variables.scss';
.BreadCrum {
    padding: 30px 0 30px 0;
    .breadcrumb{
        margin: 0; padding: 0;
        .breadcrumb-item{
            &:before{
                top:5px;
                left:-20px;
                width: 8px;
                height: 14px;
                content: '';
                position: relative;                
                background: url(../../assets/images/right-arrow.png) no-repeat 0 0;
            }            
            a{  display: inline-block;
                &::first-letter{
                    text-transform: uppercase;
                }
                margin-right: 15px;
                color: $text-blackgray-300;
                font-weight: $f-w-400;
                text-transform: lowercase;
                font-family: 'Inter', sans-serif;
            }
            &:last-child{
                a{font-weight: $f-w-700;}
            }
        }
        .active{
            // font-size: $text-lg;
            margin-left: 0;
            margin-right: 0;
            color: $text-black-1003;
            font-weight: $f-w-700;
            text-transform: capitalize;
        }
    }
}