@import '../../../assets/scss/variables.scss';
.MapCard{
    padding: 20px 0 0 0;
    .card{
        border-radius: $radius7;
        background: $text-white;
        // padding: 8px 8px 0 8px;
        margin: 0 0 10px 0;
        position: relative;
        border: 0;
        .card-body{ 
            // background: $text-white;
            // border-width: 1px 1px 0 1px;
            // border-color: $border1;
            // border-style: solid;
            h6{
                padding: 6px 15px;
                display: inline-block;
                color: $text-gray-1004;                
                color: $text-gray-300;
                text-transform: uppercase;
                border: 1px solid $border6;
                border-radius: 99px;
            }
            h5{                
                margin: 0;
                padding: 30px 0;
                font-size: $text-lg;
                font-weight: $f-w-700;
                color: $text-blackgray-100;
                text-transform: capitalize;
            }
            .card-text{
                color: $text-blackgray-100;
                svg{
                    margin-right: 10px;
                }
            }
            .card-footer{
                border: 0;
                padding: 0;
                margin: 0;
                background: transparent;
            }
            .btn-secondary{
                width: 100%;
                padding: 0.875rem;
                margin: 30px 0 0 0;
                color: $text-white;
                font-size: $test-sm;
                letter-spacing: 1px;
                font-weight: $f-w-700;
                border-radius: $radius3;                
                background-color: $primary;
            }
            // strong{
            //     font-size: $test-sm;
            //     text-transform: uppercase;
            //     display: flex;
            //     padding: 15px 0 0 0;
            // }
            span{
                position: absolute;
                top: 14px;
                right: 0;
                padding: 8px 15px;                
                font-size: $test-sm;
                background: $border4;  
                font-weight: $f-w-700;              
                color: $text-gray-1000;                
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
            }
            // .divider{
            //     height: 1px;
            //     background: $border2;
            //     width: 100%;
            //     margin: 10px 0 20px 0;
            // }
            // p{
            //     font-size: $test-small;
            //     padding: 20px 0 50px 0;
            // }
        }
        .btnDiv{
            position: absolute;
            bottom: 0; left: 0;
            width: 100%;
            background: $text-white;
            text-align: center;
            Button{
                font-family: 'Poppins', sans-serif;  
                font-weight: $f-w-400;
            }
        }
    }
}
    