@import "../../assets/scss/variables.scss";
.UseDropDownWithSearch {
  .searchLabel {
    font-family: "Raleway", sans-serif;
    border: 1px solid $border6;
    color: $text-gray-300;
    padding: 0.475rem 0.75rem;
    border-radius: $radius3;
    cursor: pointer;
    background: url(../../assets/images/bottom_arrow.png) $text-gray-1003 no-repeat 98%
      15px !important;
  }
  .searchLabelActive {
    cursor: pointer;
    position: relative;
    color: $text-gray-300;
    border-radius: $radius3;
    border: 1px solid $border6;
    padding: 0.475rem 0.75rem;
    background: $text-white !important;
    font-family: "Raleway", sans-serif;
    span {
      top: 50%;
      right: 10px;
      cursor: pointer;
      position: absolute;
      transform: translateY(-50%);
    }
  }
  .searchableList {
    z-index: 999;
    width: 100%;
    padding: 0;
    top: 72px;
    left: 0;
    position: absolute;
    flex-direction: column;
    border-radius: $radius2;

    .innerSearch {
      max-height: 300px;
      overflow: auto;
      border: 1px solid $border6;
      background: $text-white;
      border-radius: $radius2;
      .searchForm {
        padding: 10px 0 0 0;
        background: $text-white;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 999;
        input {
          border: 1px solid $border6;
          border-radius: $radius14;
          padding: 0.475rem 2.5rem;
          position: sticky;
          top: 0;
          left: 0;
          background: $text-white url(../../assets/images/search.png) no-repeat 10px
            center;
        }
      }
      .dropdownList {
        .list-group {
          .list-group-item {
            cursor: pointer;
            margin: 0;
            padding: 0.5rem;
            font-size: $test-base;
            color: $text-gray-1005;
            text-transform: capitalize;
            &:hover {
              color: $text-black-500;
              background: $text-blue-300;
            }
          }
        }
      }
    }
  }
}
