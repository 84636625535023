@import "../../assets/scss/variables.scss";
.jumbo {
  padding: 30px 60px;
  color: $text-white;
  background: $primary;
  border-radius: $radius1;
  width: 70%;
  margin: 40px auto;
  .left {
    display: flex;
    h3 {
      font-weight: $f-w-700;
      font-size: $text-3xl-small;
      text-shadow: $text-shdow1;
      font-family: "Raleway", sans-serif;
      padding: 0;
      margin: 0;
    }
    h4 {
      font-weight: $f-w-700;
      font-family: "Raleway", sans-serif;
      font-size: 3rem;
      padding: 5px 0 0 0;
      margin: 0;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 5rem;
      font-weight: $f-w-700;
      padding: 0;
      margin: 0;
    }
  }
}

@media (max-width: 768px) {
  .jumbo {
    width: 100%;
    .left {
      h4 {
        font-size: 2rem;
      }
    }
    .right {
      h2 {
        font-size: 3rem;
      }
    }
  }
}
