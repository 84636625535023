@import "../../assets/scss/variables.scss";
.DonateNow {
  padding: 60px 0;
  // h5{
  //     color: $text-black-500;
  //     font-weight: $f-w-700;
  //     font-size: $test-medium-xsmall;
  //     font-family: 'Raleway', sans-serif;
  // }
  // .card{
  //     padding: 40px;
  //     color: $text-white;
  //     border-radius: $radius7;
  //     background: $text-black-500;
  //     button{
  //         margin: 0 0 15px 0;
  //     }
  //     svg{
  //         margin-right: 5px;
  //     }
  // }
}
