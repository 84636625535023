@import '../../assets/scss/variables.scss';
.Seminar{
    padding: 50px 0;
    h2{
     font-family: 'Raleway', sans-serif;
     font-size: $test-medium-xsmall;
     font-weight: $f-w-700;
     color: $text-black-500;
     padding: 0 0 30px 0;
    }
}