@import "../../assets/scss/variables.scss";
.Login {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: $adminBg url(../../assets/images/heartbg.png) no-repeat left bottom;
  background-size: 100%;
  .innerLogin {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px 0;
    .logo {
      height: 80px;
      margin: 30px 0 0 0;
      display: inline-block;
    }
    h3 {
      margin: 0;
      text-align: center;
      position: relative;
      padding: 30px 0 25px 0;
      font-weight: $f-w-900;
      font-family: "Raleway", sans-serif;
      font-size: $test-medium-xsmall;
      color: $text-blackgray-100;
      strong {
        font-weight: $f-w-900;
        font-family: "Raleway", sans-serif;
        font-size: $test-medium-xsmall;
        color: $text-blackgray-100;
      }
      svg {
        bottom: 17px;
        right: -23px;
        position: absolute;
      }
    }

    .loginCard {
      width: 420px;
      padding: 40px;
      background: $text-white;
      border-radius: $radius7;
      box-shadow: $shadow9;
      form {
        width: 100%;
        .form-label {
          font-size: $test-sm;
          margin-bottom: 0.2rem;
        }
        button {
          width: 100%;
          background: $primary;
          margin: 30px 0 20px 0;
          font-weight: $f-w-700;
          letter-spacing: 1px;
          font-size: $test-sm;
          padding: 0.57rem 0.75rem;
          border-radius: $radius3;
        }
        input {
          height: 40px;
          font-family: "Raleway", sans-serif;
          padding: 0.575rem 0.75rem;
          border: 1px solid $border6;
          &:focus {
            border: 2px solid $text-blackgray-300;
          }
        }
        .closeEmail {
          right: 11px;
          bottom: 12px;
          cursor: pointer;
          position: absolute;
        }
      }
      .eye {
        right: 11px;
        bottom: 12px;
        cursor: pointer;
        position: absolute;
      }
      p {
        text-align: center;
        font-size: $test-sm;
        color: $text-gray-300;
        padding: 25px 0;
        margin: 0;
        font-family: "Raleway", sans-serif;
        span {
          font-size: $test-sm;
          color: $text-blue-100;
          font-weight: $f-w-700;
          font-family: "Raleway", sans-serif;
        }
      }
      .form-check {
        input {
          margin-right: 10px;
        }
        label {
          margin: 4px 0 0 0;
          font-size: $test-sm;
          color: $text-gray-1005;
          font-family: "Raleway", sans-serif;
        }
      }
      a {
        color: $text-blue-50;
        font-size: $text-small;
        font-family: "Raleway", sans-serif;
      }
      .form-check {
        display: flex;
        align-items: center;
        input[type="checkbox"] {
          height: 28px;
          width: 28px;
          border-radius: $radius2;
          border: 2px solid $text-red-700;
          box-shadow: none;
          outline: 0;
        }
      }
      .customBtn {
        width: 47%;
        font-size: $test-sm;
        letter-spacing: 1px;
        font-weight: $f-w-700;
        background: transparent;
        color: $text-gray-1004;
        border: 2px solid $border6;
        padding: 0.4rem 0.75rem;
        border-radius: $radius3;
        font-family: "Raleway", sans-serif;
        svg {
          float: left;
        }
      }
      .oulineBnt {
        width: 100%;
        font-size: $test-sm;
        letter-spacing: 1px;
        font-weight: $f-w-700;
        border-radius: $radius3;
        padding: 0.4rem 0.75rem;
        border: 2px solid $text-red-200;
        font-family: "Raleway", sans-serif;
        &:hover {
          color: $primary;
          background-color: $text-white;
        }
      }
      .orDiv {
        font-size: $test-sm;
        color: $text-gray-300;
        position: relative;
        height: 20px;
        width: 100%;
        text-align: center;
        margin: 0 0 30px 0;
        span {
          top: -3px;
          z-index: 5;
          padding: 0 7px;
          position: relative;
          font-size: $text-small;
          color: $text-gray-1001;
          background: $text-white;
        }
        &:before {
          content: "";
          background: $text-gray-1002;
          height: 1px;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .innerLogin {
    
    h3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg{
        position: relative !important;
        margin-top: 15px;
        right: -12px!important;
      }
    }
  }
}
