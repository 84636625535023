@import '../../assets/scss/variables.scss';
.Donate {
    background: url(../../assets/images/heart.png) no-repeat $text-red-400 right center;
    padding: 0;
    display: flex;
    flex-direction: column; 
    align-items: center;
    text-align: center;
    height: 320px;
    .container{
        height: 100%;
        display: flex;        
        align-items: center;
        flex-direction: column;
        justify-content: center;
        h2{        
            font-size: $text-3xl-small;
            font-weight: $f-w-700;
            color: $text-red-500;
            padding: 0 0 30px 0;
           }
           Button{
                border: 0;                
                color: $primary;
                padding: 7px 80px;
                box-shadow: $shadow2;                
                font-size: $test-sm;
                font-weight: $f-w-700;
                border-radius: $radius3;
                background: $text-white;
                &:active,&:focus{
                    color: $primary;
                    background: $text-white;
                }
           }
    }   
}

@media (max-width: 768px) {
    .Donate{
        h2{
            font-size: 1.8rem !important;
        }
    }
}