.video {
    padding: 100px 0;
    .content{
        height: 500px;
        overflow: hidden;
        .large-image{
            width: 100%;
        }
        .videList{
            display: flex;    
            height: 500px;
            overflow-x: hidden;
            overflow-y: auto;
            flex-direction: column;
        }
    }
}