@import '../../assets/scss/variables.scss';
.AddProfile {
    .profileBtn{
        position: absolute;
        bottom: 40px; right: 40px;
    }
    h2{
        margin: 0; padding: 0 0 30px 0;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
    }
}