@import '../../assets/scss/variables.scss';

.searchFilter {
    position: relative;

    .showValues {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        cursor: pointer;
        color: $text-gray-1004;
        padding: 0.500rem 0.75rem;
        border-radius: $radius3;
        background: $text-gray-1003 url(../../assets/images/bottom_arrow.png) no-repeat 95% center;
    }
    .clearFilters{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5px;
        color: $active;
        cursor: pointer;
    }
    .hidden{
        display: none !important;
    }
    .listOfValues {
        width: 300px;
        position: absolute;
        top: 45px;
        right: 0;
        z-index: 9999;
        padding: 15px;
        flex-direction: column;
        border-radius: $radius3;
        background-color: $text-white;
        border: 1px solid $text-gray-300;
        z-index: 9999;
        .list-group{
            max-height: 250px;
            overflow: auto;
        }
        button {
            color: $active;
            padding: 1rem 0.75rem;
            margin: 10px 0;
            font-weight: $f-w-700;
            border-radius: $radius13;
            border: 1px solid $border5;
            background-color: $text-white;

            svg {
                position: relative;
                left: -13px;
                top: -2px;
            }

            &:hover,
            &:active {
                color: $active;
                border: 1px solid $border5;
                background-color: $text-white;
            }
        }

        .search {
            width: 100%;
            border: 1px solid $border6;
            border-radius: $radius9;
        }

        .active {
            border-radius: $radius3;
            background-color: $text-blue-300;

            label {
                width: 100%;
                font-weight: $f-w-700;
                color: $text-black-500;
            }
        }

        .list-group-item {
            border: 0;
            display: flex;
            flex-direction: row;
            padding: 0.475rem 0.75rem;

            .form-check {
                width: 100%;

                label {
                    width: 100%;
                }

                input[type='checkbox'] {
                    width: 19px;
                    height: 19px;
                    border-radius: $radius9;
                    box-shadow: none;
                    border: 2px solid $text-gray-1004
                }

                input:checked {
                    background-color: $active;
                    border-color: $active;
                }
            }
        }
    }

    .check{
        margin-top: 10px !important;
    }

    .form-check.radio {
        padding-top: 7px;
        padding-left: 30px;
        padding-bottom: 10px;
    }

    .form-check.selected {
        border-radius: 12px;
        background-color: #EBF2FF;

    }

    .form-check-input {
        border-color: $active;
        position: relative;

    }
    svg{
        margin-left: 5px;
    }
    .form-check-input:checked {
        background-color: transparent;
        border-color: $active;
        border: 2px solid $active;
    }

    .form-check-input:focus {
        border-color: $active;
        outline: 0;
        box-shadow: none;
    }

    .form-check-input:checked[type=radio] {
        --bs-form-check-bg-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%232264D1%27/%3e%3c/svg%3e');
    }
}