@import '../../../assets//scss/variables.scss';

.CardSix {
    h5 {
        margin: 0;
        padding: 0 0 0 0;
        color: $text-black-500;
        font-weight: $f-w-700;
        font-size: $test-medium-xsmall;
    }

    svg {
        margin-top: -20px;
    }

    .card {
        width: 500px;
        padding: 40px;
        color: $text-white;
        border-radius: $radius7;
        background: $text-black-500;

        .card-title {
            margin: 0 0 16px 0;
            font-weight: $f-w-700;
            font-size: $test-small-light;
        }

        p {
            font-size: $test-small;
            color: $text-black-700;
            margin: 0;
            padding: 0;
        }

        button {
            margin: 5px 0 15px 0;
            width: 100%;
        }

        svg {
            margin-right: 5px;
        }

       
    }
    span {
        display: flex !important;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg{
            margin: 0;
        }
    }
}