.CardSliderFour {
    width: 100%; height: 100%;     
    .noSlots{
        display: flex;
        align-items: center;
        justify-content: center;
    }   
    .carousel-root{
        height: 100%;
    .carousel{
        .thumbs-wrapper{display: none;}
    }    
    .carousel-slider{
        width: 100%; height: 100%;
        .control-dots,.carousel-status{display: none;}
        .control-arrow.control-prev{
            // border: 1px solid green;
            height:50px; top:50%; 
            transform: translateY(-50%);
            background: transparent; padding: 0;
            width: 44px; height: 45px;
            &::before{
                width: 44px; height: 45px; border: 0; margin: 0;
                background: url(../../../assets/images/prev.png) no-repeat;                
            }
        }
        .control-arrow.control-next{
            height:50px; top:50%; 
            transform: translateY(-50%);
            background: transparent; padding: 0;
            width: 45px; height: 45px;
            &::before{
                width: 44px; height: 45px; border: 0; margin: 0;
                background: url(../../../assets/images/next.png) no-repeat;
            }
        }
        .control-next{
            border: 1px solid;
        }
        .slider-wrapper{
            width: 90%;
            margin: 0 auto;
        }
        .slider{
            width: 34.5%;
            padding: 0 20px;
            .slide{
                margin-right: 22px;
            }
        }
    }
}
}